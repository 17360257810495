export default {
    pointDistance: 20,
    heightMap:[
      [
        8.92107578277588,
        9.788160133361817,
        7.861060935974123,
        9.440376091003419,
        10.209499916076659,
        9.031443290710449,
        10.165786018371584,
        9.456785209655763,
        7.750543785095214,
        5.1407178573608405,
        8.981766090393066,
        13.70726016998291,
        11.547871131896972,
        7.058715492248535,
        9.056641242980959,
        8.883538398742676,
        6.237693122863767,
        8.008977943420412,
        9.391010124206545,
        8.191526237487793,
        9.085124473571778,
        9.682884819030761,
        5.555973060607911,
        12.147734199523924,
        10.315572273254396,
        8.658521156311036,
        5.789900062561035,
        7.200111640930174,
        10.1235717086792,
        10.203907814025879,
        8.834744987487795,
        11.12030088043213,
        9.088859352111818,
        9.493977943420411,
        10.726667457580568,
        8.935733375549319,
        5.206944465637207,
        5.023521354675292,
        10.752056953430177,
        13.396599342346192,
        9.051073417663574,
        7.95350299835205,
        7.758287895202635,
        9.476233406066896,
        11.002737281799316,
        9.129913597106935,
        7.344880424499511,
        8.27302854156494,
        8.313920356750486,
        6.324817390441893,
        8.67104351043701,
        9.67028639984131,
        9.232873481750488,
        9.375776451110838,
        9.56496492767334,
        8.34978488922119,
        7.859343635559082,
        7.634967628479004,
        8.861756538391113,
        8.361673027038574,
        8.931464424133303,
        13.619384300231935,
        9.679739082336425,
        6.514485481262206,
        6.129127296447756,
        8.796854743957518,
        8.923641654968261,
        9.302484321594239,
        9.488334663391115,
        11.0894831161499,
        9.131720619201658,
        9.98042600250244,
        7.96567389678955,
        9.792331901550291,
        10.28180188751221,
        8.982971153259278,
        7.920674736022949,
        8.367134300231932,
        8.714283149719236,
        8.001460731506349,
        8.817492332458496,
        8.011992835998534,
        8.191750846862792,
        9.336494804382326,
        10.629550178527833,
        8.826295890808105
      ],
      [
        11.758566505432128,
        9.896190788269045,
        12.54643773651123,
        12.393606590270998,
        7.82960945892334,
        5.944901977539061,
        5.912113044738771,
        8.181701957702636,
        8.583943183898924,
        6.457118034362793,
        10.771258476257323,
        14.091081672668459,
        10.740201347351075,
        7.257558494567871,
        8.572745918273924,
        7.059436027526857,
        6.459752571105959,
        12.327976753234864,
        10.381382835388184,
        9.62537929534912,
        9.69438698577881,
        11.42661243438721,
        9.175235069274905,
        10.49297046661377,
        7.765764793395995,
        6.459915931701659,
        6.224115150451659,
        6.493328163146973,
        10.994355644226076,
        9.95527793121338,
        10.997570976257322,
        11.862495582580564,
        10.033669685363769,
        8.55718985748291,
        11.886848838806152,
        11.324168006896972,
        5.498787635803222,
        7.137730857849121,
        8.918763938903808,
        10.22382611846924,
        8.263704948425293,
        6.693200752258299,
        5.50201667022705,
        9.477932197570802,
        9.950625236511229,
        10.333074806213379,
        11.00045220184326,
        7.1227009201049825,
        6.723778022766112,
        7.623777168273925,
        12.580077690124511,
        12.36920401763916,
        10.924126640319827,
        8.224715202331545,
        5.912124931335448,
        4.269130546569826,
        5.904361808776855,
        7.830482429504393,
        10.515302345275877,
        8.36982382965088,
        9.306905418395996,
        10.640011238098147,
        7.42505556488037,
        7.126181571960451,
        4.792783027648927,
        7.85520774078369,
        8.19174584197998,
        11.591528755187987,
        10.29672274017334,
        10.696920570373535,
        7.904488029479982,
        5.657824760437013,
        3.3383432846069336,
        3.8391653289794903,
        7.220201210021973,
        8.228157539367675,
        8.806735404968261,
        12.128058509826658,
        11.113109718322754,
        9.889273109436033,
        10.591069175720214,
        10.824298973083494,
        9.076552085876465,
        8.08738471221924,
        7.4781403274536125,
        7.094290763854978
      ],
      [
        10.088441246032716,
        5.9853275375366195,
        9.755255439758301,
        11.452002738952636,
        10.415624687194825,
        7.0757036056518565,
        4.618350440979004,
        7.671717658996581,
        8.341522422790526,
        5.408648780822752,
        8.7586517868042,
        13.278647621154786,
        9.758021980285644,
        8.62240229034424,
        12.861477317810056,
        9.119121101379395,
        6.475960151672364,
        10.97343579864502,
        7.741775306701662,
        4.873287391662597,
        8.026427101135255,
        13.1206937789917,
        10.706221885681153,
        6.680950401306152,
        8.28354206085205,
        6.678469047546386,
        5.799273139953613,
        8.381122352600098,
        8.31873737335205,
        7.671354179382323,
        10.012897514343262,
        11.906253120422363,
        9.270134971618653,
        9.537428276062013,
        13.946743644714356,
        11.737517997741698,
        8.414590080261231,
        9.188904914855957,
        8.35859787750244,
        7.4641267013549815,
        8.591715614318847,
        11.990313896179199,
        7.480042472839354,
        7.658243309020996,
        7.400868171691894,
        9.59982123565674,
        10.634843788146974,
        9.967121620178222,
        7.875531349182129,
        7.812124565124513,
        11.267607887268067,
        10.661649589538577,
        9.791785957336426,
        7.364041358947752,
        7.752034980773928,
        6.1177902603149406,
        5.209215278625489,
        8.994571556091309,
        12.433536476135252,
        9.871847023010254,
        10.546603797912596,
        10.133603538513185,
        7.777302223205567,
        7.601026786804198,
        6.749956520080566,
        7.148460731506347,
        7.359715660095215,
        10.732266242980955,
        11.973030952453612,
        8.913496391296388,
        5.027885063171386,
        5.683592964172362,
        5.932204368591307,
        6.414246711730957,
        9.43487442779541,
        10.735559730529786,
        9.082732261657714,
        7.658996604919434,
        10.475706459045412,
        9.544833595275879,
        11.1520112991333,
        10.395453773498534,
        7.859555183410645,
        6.155820167541503,
        8.425016792297363,
        9.783311561584473
      ],
      [
        6.956238410949709,
        6.046248435974121,
        6.402697029113771,
        6.307806297302246,
        9.779756889343261,
        9.047588096618654,
        8.917165763854982,
        10.748672645568845,
        11.438264991760256,
        8.309277046203611,
        8.787286201477052,
        11.687202018737793,
        10.12528913116455,
        10.350620735168455,
        9.615901268005373,
        6.516097846984863,
        6.184552070617677,
        8.324628562927245,
        5.1139795608520515,
        5.533320259094239,
        10.482814216613768,
        11.067186500549319,
        11.38450382232666,
        7.744704017639158,
        10.19942797088623,
        10.562068641662599,
        9.596609504699707,
        9.709109092712403,
        7.2774083633422855,
        4.53562621307373,
        9.954439598083496,
        10.699584510803223,
        9.18372161102295,
        9.015789390563967,
        10.767979484558104,
        9.169337913513182,
        7.193456336975096,
        10.071627906799314,
        6.456324165344238,
        5.612108634948729,
        9.39896164703369,
        12.494784004211425,
        10.072718757629396,
        7.579169044494627,
        6.386143684387207,
        7.352607612609862,
        8.566902488708497,
        8.798143928527832,
        5.771767707824708,
        4.144359306335449,
        5.76461582183838,
        8.551943122863769,
        10.676648490905762,
        9.987574317932129,
        12.057798622131347,
        11.161844856262206,
        8.76943071746826,
        5.512900215148925,
        7.12646378326416,
        9.619756202697754,
        8.57334236907959,
        9.631572715759276,
        10.37368189239502,
        7.752799339294434,
        7.434205970764161,
        10.713407997131348,
        6.656833244323732,
        5.3828503494262705,
        9.491782936096193,
        11.221699485778807,
        10.472338294982912,
        8.365696205139159,
        9.6754878616333,
        9.399596946716308,
        8.318016105651857,
        7.461836265563964,
        6.8366033096313465,
        7.084365257263183,
        11.482727668762209,
        10.31889708709717,
        7.74720597076416,
        7.925264427185059,
        10.24801210784912,
        10.372071968078615,
        9.562377479553223,
        9.340074073791506
      ],
      [
        8.228250587463378,
        5.229627098083498,
        7.3119631729125985,
        5.120270652770998,
        6.280109809875487,
        10.453851585388186,
        9.858391120910646,
        9.292826789855958,
        11.237002540588378,
        12.400152458190917,
        12.113019844055174,
        11.887653160095216,
        11.415538734436034,
        12.81913454437256,
        8.248961433410642,
        6.532608406066895,
        8.02471262359619,
        8.480494667053222,
        5.420792121887207,
        5.811020454406737,
        8.628458473205566,
        8.972293479919433,
        10.024295722961426,
        6.31808811187744,
        9.421260902404786,
        7.360919639587401,
        5.960694160461426,
        8.26732772064209,
        10.3185913772583,
        6.084916954040526,
        5.499211235046388,
        9.483417900085449,
        9.791566184997556,
        6.112415245056152,
        6.845898429870605,
        7.3282497940063465,
        11.90281327056885,
        13.017794654846192,
        6.481681785583497,
        6.0657234725952165,
        9.928358940124511,
        10.865627220153808,
        10.669514045715331,
        6.172057014465331,
        6.0323145217895515,
        5.83889380645752,
        9.571857749938964,
        7.137520210266114,
        5.494558647155763,
        2.3009542541503905,
        3.594891593933106,
        8.598807365417478,
        13.273674858093262,
        13.303875465393066,
        11.000626136779786,
        10.50073511505127,
        9.672999076843261,
        5.308274711608888,
        3.000710700988769,
        8.498451347351072,
        8.45957230377197,
        9.685859504699707,
        10.868119972229003,
        8.839715995788573,
        8.556236763000488,
        11.755863578796387,
        10.046526588439942,
        5.70003279876709,
        10.563229209899902,
        13.130182273864747,
        10.218163246154788,
        6.915647132873534,
        9.671835655212405,
        14.232181114196777,
        11.180357688903811,
        5.3693261795043945,
        5.8108622512817405,
        8.52992810821533,
        6.656494438171386,
        4.636332695007326,
        7.073312446594237,
        12.823081855773928,
        14.97654093170166,
        10.62084804534912,
        7.262683876037599,
        8.664253440856932
      ],
      [
        9.028504371643066,
        8.515577156066897,
        12.449361778259277,
        5.802339073181152,
        4.64683945465088,
        9.020617485046387,
        10.110781929016113,
        8.514121315002441,
        10.754479057312011,
        11.3523345413208,
        8.774720878601073,
        8.502107917785644,
        7.167128089904786,
        9.166253456115722,
        7.898590614318849,
        9.136616401672363,
        12.047098609924316,
        11.624346549987791,
        5.248503639221191,
        7.057747016906738,
        8.816820182800292,
        8.099630073547363,
        9.446432945251466,
        9.144385551452636,
        10.375644386291505,
        8.730160636901857,
        6.8728838272094706,
        6.451584571838378,
        11.89180673980713,
        9.98251194000244,
        8.888440895080567,
        10.93360015106201,
        8.9921127243042,
        7.023177192687989,
        5.791331184387207,
        8.631199531555175,
        13.609456703186034,
        13.577468894958496,
        6.103416435241698,
        4.027164337158204,
        8.360104866027832,
        10.634552497863769,
        7.950989814758302,
        9.494375068664553,
        5.440957695007324,
        8.72727710723877,
        13.388424552917481,
        10.418328041076663,
        8.89785196685791,
        6.972298652648924,
        8.87362186431885,
        9.958389823913574,
        11.295787055969239,
        6.1902018203735345,
        8.322965156555178,
        8.68029613494873,
        10.149310615539552,
        10.159268714904783,
        7.990823890686034,
        9.783183601379395,
        8.855962257385254,
        8.997490226745605,
        11.82820694732666,
        5.911002616882325,
        4.7878542861938485,
        8.817509422302244,
        8.748470649719238,
        8.426195655822752,
        10.729605018615722,
        13.439547447204589,
        9.264820365905763,
        4.007277839660645,
        8.137218406677245,
        10.060967903137207,
        12.716981437683106,
        9.300848808288574,
        9.059083671569823,
        8.534418647766113,
        5.815417823791503,
        5.309315727233887,
        9.231363105773926,
        12.85525707244873,
        10.450233360290525,
        7.300495307922361,
        4.9807095565795905,
        8.47939945220947
      ],
      [
        9.894302192687988,
        12.047040748596192,
        10.093586387634275,
        9.541678260803224,
        6.530621223449707,
        9.143202934265137,
        9.136409156799317,
        9.274817924499512,
        9.912607261657715,
        9.268426414489745,
        7.81674890899658,
        7.931707710266114,
        4.879243690490725,
        6.548055488586428,
        11.159006690979005,
        14.910735557556151,
        15.329385475158691,
        11.739964576721192,
        7.210684226989745,
        8.26323349761963,
        11.401124626159667,
        10.290433921813964,
        11.868470893859865,
        8.850839775085447,
        10.57772548675537,
        9.512353050231933,
        8.12633408355713,
        8.348144721984863,
        12.90327283477783,
        13.180557182312011,
        12.15292748260498,
        11.161909660339354,
        9.145099281311033,
        5.428901481628419,
        4.393108222961427,
        5.311078071594238,
        8.80991138458252,
        10.094514305114746,
        8.751288291931154,
        4.320479064941406,
        4.736110671997071,
        6.889797508239745,
        8.636169837951662,
        10.558122428894045,
        8.735138206481935,
        10.205419319152831,
        12.150404701232908,
        11.81769507598877,
        9.141046577453615,
        9.864244789123536,
        12.58638306427002,
        10.211046470642088,
        6.985749710083006,
        5.649265251159669,
        5.873107978820801,
        8.317105567932131,
        11.05051734161377,
        13.84075695037842,
        11.998901252746585,
        11.224125862121582,
        10.052019645690919,
        7.909256416320801,
        8.984823829650878,
        6.283319221496583,
        5.2364250259399405,
        8.039778144836426,
        9.059848289489747,
        9.55994275665283,
        11.599756538391112,
        12.904945213317873,
        12.100471229553222,
        6.578301139831545,
        8.505853233337405,
        11.28271138763428,
        12.875840049743651,
        11.519881477355959,
        9.115417076110838,
        8.681176322937013,
        6.853140830993652,
        8.577300804138183,
        10.724259330749511,
        12.306540931701662,
        6.019404808044435,
        3.81589134979248,
        7.034510444641114,
        8.862405586242676
      ],
      [
        10.880996040344236,
        11.10150693511963,
        9.77479212188721,
        9.801577491760256,
        10.446736793518067,
        8.676010597229002,
        7.412125495910644,
        6.015663154602052,
        7.640060890197752,
        5.532225273132326,
        9.103596183776855,
        10.930789070129393,
        8.685402992248534,
        6.94002657318115,
        9.466606727600098,
        11.241501457214355,
        12.955250144958498,
        11.868509468078614,
        7.433905021667481,
        8.201136360168455,
        10.412048988342285,
        10.331188102722166,
        8.077284706115723,
        7.532034690856932,
        10.045118324279784,
        7.380088325500488,
        6.793357124328615,
        8.328616065979006,
        10.911842109680176,
        10.914922584533691,
        11.778424873352051,
        10.436368919372558,
        9.019789146423342,
        5.711283271789551,
        6.151188758850097,
        7.906556938171388,
        7.327617469787597,
        9.551684974670408,
        10.100954414367678,
        9.2594938583374,
        7.309810371398925,
        5.027647956848146,
        7.4997355880737295,
        11.572277687072756,
        9.17206064605713,
        10.309438362121583,
        9.798742195129392,
        11.123384681701658,
        10.582213233947755,
        11.572624671936033,
        10.798493659973147,
        6.649759788513183,
        5.366159111022949,
        5.312237876892091,
        6.134995582580565,
        7.379400169372559,
        9.450571937561037,
        11.171203041076659,
        10.912235603332519,
        10.29780675506592,
        8.850824699401855,
        12.303563453674315,
        10.946188423156737,
        7.011268852233886,
        5.573138298034667,
        7.466605339050293,
        10.811566780090331,
        10.001535881042479,
        10.05630460357666,
        9.488864189147947,
        10.25696477508545,
        8.715976264953614,
        8.96716039276123,
        11.10913372039795,
        12.616665901184081,
        9.394051338195801,
        7.552108787536621,
        6.034258399963377,
        7.916062751770021,
        6.7710080642700206,
        9.074198219299316,
        8.2754603805542,
        6.807275703430175,
        5.634937400817872,
        7.5391133041381835,
        8.769690711975098
      ],
      [
        7.643405769348146,
        8.744304557800291,
        9.197744880676268,
        7.804053001403808,
        10.150414726257324,
        8.72107869720459,
        6.348784004211424,
        8.424994956970217,
        9.275485939025877,
        5.002408378601075,
        7.202477836608885,
        12.915637641906738,
        12.646987648010253,
        9.523656532287596,
        6.908460456848147,
        7.019856300354004,
        9.352080909729004,
        9.843165061950685,
        4.664221611022949,
        4.323379173278809,
        6.796246589660644,
        8.70370693206787,
        10.080368843078613,
        10.63686548614502,
        12.173900245666502,
        10.82585440826416,
        8.24812947845459,
        7.7908047103881835,
        10.732709022521973,
        8.624710807800291,
        8.188594123840332,
        10.0859817276001,
        10.792363716125486,
        8.267505577087402,
        9.984730613708496,
        9.141023628234862,
        5.747514533996583,
        6.562198951721193,
        9.288022010803225,
        12.375956825256345,
        10.789959602355957,
        7.5858187332153335,
        9.083280281066894,
        12.433673515319825,
        9.800138206481932,
        7.686489982604982,
        6.960828590393067,
        8.157048072814941,
        11.131982063293457,
        10.147867927551268,
        7.583191764831543,
        5.930022712707519,
        8.855686729431152,
        8.632846839904786,
        9.267714637756349,
        11.415870338439941,
        12.015685661315917,
        10.486841789245606,
        9.377363395690917,
        7.371707527160645,
        6.624850151062013,
        8.04018840789795,
        8.637837745666502,
        6.976747032165527,
        5.932784400939941,
        11.01485974884033,
        11.991109230041502,
        8.5310425491333,
        5.666994987487794,
        5.1138891525268555,
        5.24143022918701,
        5.668836524963378,
        8.39187798309326,
        10.971204383850097,
        11.083635993957522,
        11.067804878234863,
        9.336160148620607,
        11.275104545593262,
        11.219692558288575,
        6.641307350158691,
        8.459095970153811,
        11.465201438903808,
        11.714484001159668,
        8.069920509338381,
        7.994317100524903,
        10.149970832824707
      ],
      [
        10.026462730407715,
        11.072660118103027,
        7.267151664733888,
        4.921049644470216,
        7.919234031677245,
        5.160468315124511,
        4.397961906433103,
        8.288606117248534,
        8.088909049987791,
        8.52815732574463,
        8.681633979797361,
        11.589747413635251,
        10.57189968109131,
        10.108063621520998,
        8.565525260925291,
        9.58016414642334,
        6.179605232238771,
        5.821269172668458,
        6.346483741760252,
        7.451140968322754,
        5.334027854919434,
        4.576346138000488,
        10.002858070373534,
        7.956340904235841,
        11.221697883605955,
        10.808255973815918,
        6.83605980682373,
        6.39530509185791,
        9.494014106750488,
        8.818818702697754,
        8.222522377014158,
        10.518202583312988,
        11.04594721221924,
        9.5980581741333,
        10.225051918029784,
        9.296887672424319,
        7.652518241882326,
        6.357763679504394,
        11.67191007232666,
        11.395169639587401,
        8.401769065856932,
        8.771553062438965,
        13.444028419494629,
        14.975819221496582,
        11.105136756896975,
        10.388396492004397,
        8.57313288116455,
        10.327251625061034,
        13.56183567047119,
        9.362783210754394,
        5.8859867477417005,
        7.672273948669435,
        9.01254697418213,
        7.217751441955567,
        8.921550575256347,
        13.325834678649905,
        14.740021247863769,
        12.494360939025881,
        8.278070289611819,
        8.597538063049317,
        7.81706531524658,
        5.550469062805178,
        4.517471855163574,
        6.384558631896972,
        7.437641853332519,
        11.521131629943845,
        12.039412391662598,
        7.172641014099121,
        2.3211971664428717,
        3.8907014160156237,
        4.1371524581909185,
        6.65370613861084,
        5.860708366394043,
        7.662575813293458,
        9.065710899353029,
        6.6450614089965825,
        9.30332006072998,
        11.455236656188966,
        10.607147468566893,
        8.20768476104736,
        5.436350624084472,
        7.081739082336426,
        9.53776796722412,
        9.033504890441897,
        11.986521461486817,
        10.941617012023926
      ],
      [
        9.056193046569824,
        11.187407157897951,
        7.43452561187744,
        10.134037147521973,
        7.9098682022094735,
        8.724919929504395,
        7.404582405090331,
        7.981794731140138,
        10.061963386535645,
        11.997439918518069,
        9.507601737976074,
        10.684830726623535,
        11.565431983947754,
        8.343128349304202,
        6.845997550964356,
        8.514774055480956,
        5.580092247009276,
        6.912359535217284,
        11.59892490386963,
        13.039055091857911,
        9.186677665710448,
        8.895649421691893,
        9.149996437072755,
        7.765771400451662,
        10.3297953414917,
        8.544653511047365,
        6.030102882385254,
        6.10957999420166,
        9.296432762145997,
        9.472366905212404,
        9.403432273864745,
        11.502290092468261,
        11.670095054626465,
        6.215745231628418,
        8.033524482727053,
        9.195726051330567,
        6.150731010437013,
        6.990182258605955,
        10.983834648132323,
        11.873473945617675,
        9.095990257263182,
        8.867718009948732,
        8.3266831741333,
        10.254958198547364,
        9.854382789611819,
        8.838114585876465,
        4.908745643615724,
        8.464734077453613,
        8.779570793151855,
        10.778315879821776,
        8.681697044372559,
        9.481503608703612,
        10.940974494934082,
        7.247358512878417,
        7.2251544876098635,
        9.371048774719238,
        10.214092445373534,
        9.922220863342286,
        8.7291330947876,
        9.042335044860842,
        8.750261878967287,
        7.930780036926269,
        9.104896766662598,
        7.796935691833497,
        7.7314633255004885,
        8.849963035583496,
        10.959824378967284,
        9.42439235687256,
        6.63406455230713,
        7.143391532897951,
        9.08006778717041,
        9.405330924987794,
        8.84263500213623,
        7.074890663146974,
        9.288023674011228,
        9.35339641571045,
        10.660520393371584,
        8.813063087463377,
        9.846808784484864,
        7.287087638854981,
        8.791076316833497,
        8.11923979949951,
        7.912481651306152,
        9.033227882385255,
        11.806606788635253,
        9.287718620300293
      ],
      [
        7.706120018005372,
        7.458107109069825,
        9.665605323791503,
        11.612931755065919,
        8.867727180480955,
        10.403086769104004,
        8.76492699432373,
        7.731448081970214,
        10.425873908996582,
        13.658375831604005,
        12.46680652618408,
        11.904107795715333,
        9.98978672027588,
        4.988762107849119,
        4.727771675109864,
        4.039310371398926,
        4.5551025161743155,
        6.439650672912599,
        11.173576148986818,
        14.2695403213501,
        13.073488914489744,
        13.563439643859866,
        8.541323768615722,
        9.538029792785643,
        9.487247749328613,
        9.063895713806154,
        7.734633689880372,
        9.1407008895874,
        7.142609230041504,
        8.561965187072754,
        8.418937980651855,
        9.004975410461427,
        8.948738121032715,
        7.222928672790527,
        7.201189170837402,
        9.311158897399903,
        7.110443931579589,
        5.043637275695801,
        10.3623344039917,
        12.89017211151123,
        11.70096268463135,
        8.412768379211428,
        4.3886754684448235,
        7.380407127380373,
        6.719150566101074,
        5.873406517028808,
        4.3164855728149405,
        7.657745414733888,
        7.042432304382324,
        10.437056602478027,
        9.930753364562989,
        9.685380622863768,
        10.072580207824707,
        12.449019844055176,
        10.763864952087403,
        7.7040792770385735,
        6.517299079895018,
        9.12480593109131,
        7.49140586090088,
        11.464024635314942,
        9.989551033020021,
        10.251292884826661,
        12.12856095123291,
        10.970618995666506,
        7.190979255676268,
        5.6495051956176745,
        8.367866981506346,
        10.626713691711426,
        10.471633094787599,
        10.52038286590576,
        13.506306892395017,
        12.267441001892088,
        8.496093650817869,
        8.630010688781738,
        9.013696281433106,
        10.896580970764163,
        13.02755152130127,
        10.87458518218994,
        7.845749137878418,
        7.445180427551271,
        9.39018939971924,
        9.025405494689942,
        8.996696083068848,
        11.081494132995605,
        8.93988565826416,
        7.926907997131348
      ],
      [
        8.754201637268068,
        5.94759644317627,
        6.235326805114745,
        10.01919065093994,
        11.474819190979005,
        10.678509635925295,
        7.209700340270995,
        8.348289222717284,
        9.36493968963623,
        11.441519355773927,
        10.354461647033691,
        10.719031303405764,
        9.154293525695799,
        6.132713066101076,
        3.955818069458008,
        7.904099876403809,
        8.16099192047119,
        7.002069557189941,
        10.396450386047363,
        11.016718559265136,
        10.522001609802246,
        10.604363044738768,
        9.027385749816894,
        7.235550544738771,
        4.255198616027833,
        8.261943351745607,
        10.386303657531741,
        9.02717978668213,
        6.123862068176269,
        9.64551068878174,
        9.048881065368654,
        7.04611466217041,
        5.145239051818848,
        9.28781499481201,
        7.593403587341307,
        8.385958106994629,
        7.678080772399902,
        5.195741905212401,
        9.15774878692627,
        10.990954154968264,
        11.734067070007324,
        7.010803718566894,
        6.400016189575197,
        8.506271003723143,
        8.489595680236818,
        7.262297050476075,
        9.410093299865721,
        8.225765388488767,
        7.715600563049318,
        10.756951118469239,
        9.076366722106933,
        5.643757987976073,
        6.210916572570802,
        11.440059700012206,
        11.458318077087402,
        8.567787681579588,
        7.077268104553223,
        8.13392133331299,
        7.967034584045411,
        12.55166419219971,
        11.012400840759277,
        9.778786277770998,
        11.626824913024905,
        9.078813331604003,
        4.90742975616455,
        6.17882444000244,
        8.126758872985839,
        10.020436546325683,
        10.01903897857666,
        11.257374931335448,
        11.3541987991333,
        9.068750419616698,
        9.458239799499509,
        8.699475929260252,
        8.198761085510256,
        9.160614768981933,
        11.757739219665527,
        11.742467079162598,
        7.534300483703614,
        6.496186500549317,
        6.439062431335451,
        7.098088386535643,
        8.250241340637206,
        10.81547107696533,
        8.445592735290525,
        7.668083946228027
      ],
      [
        8.974233345031738,
        4.585627098083496,
        5.6540759048461915,
        10.375883125305176,
        12.051509849548339,
        9.03099312591553,
        7.128195701599122,
        9.856792106628419,
        11.498122276306152,
        10.897618309020999,
        8.543768928527832,
        12.582670326232908,
        10.136777229309082,
        7.792961921691894,
        6.069720741271972,
        11.168296623229981,
        10.19671474456787,
        8.959055335998535,
        10.930484153747557,
        9.255745109558106,
        7.718572334289551,
        9.382146598815918,
        11.344140785217284,
        8.463014137268068,
        7.760503944396973,
        10.956152687072752,
        15.399225212097168,
        13.103211280822755,
        8.473440773010253,
        9.383888221740722,
        9.080191993713377,
        5.597716239929198,
        6.413203773498534,
        9.368964164733889,
        6.270813743591308,
        7.882785026550295,
        10.275134284973145,
        9.10198445892334,
        11.781498374938963,
        12.324519538879393,
        8.141640800476075,
        5.2831200485229495,
        9.240530342102051,
        11.321888526916503,
        10.306700416564944,
        7.3901362991332995,
        10.234760307312012,
        7.491097785949705,
        9.318131690979001,
        9.762777641296388,
        9.044076499938967,
        5.091907478332519,
        6.66016349029541,
        12.220954872131347,
        12.652129585266113,
        7.8641379165649425,
        6.382033714294433,
        8.25046314239502,
        9.501534797668455,
        10.646610527038575,
        7.154293693542481,
        6.854322517395019,
        8.584295539855958,
        7.555661537170408,
        8.193624275207519,
        10.789293785095214,
        10.302907386779786,
        11.430033546447753,
        9.498095359802246,
        8.710214653015136,
        7.486213264465331,
        4.778593101501464,
        7.6605757522583,
        9.006151802062988,
        8.142895317077638,
        8.773633613586426,
        10.679067420959473,
        9.670009544372558,
        4.3303926620483395,
        7.5613436355590835,
        10.873779228210449,
        11.65900984954834,
        8.051181159973144,
        10.715676185607911,
        10.455084693908692,
        9.494947120666502
      ],
      [
        7.965773445129393,
        7.963942939758301,
        7.831558937072753,
        6.442807197570801,
        5.950675727844239,
        7.829026954650878,
        11.767455421447753,
        12.480332908630373,
        10.585141151428225,
        7.56393140411377,
        7.286646659851073,
        10.423272468566893,
        12.126935737609863,
        10.461567054748535,
        8.800056297302248,
        11.665069450378418,
        11.005958839416504,
        8.627691581726076,
        8.218816108703614,
        7.934562522888186,
        4.32254663848877,
        7.6759483413696294,
        14.873055366516112,
        12.084440620422363,
        8.054170860290526,
        9.793445030212403,
        10.836548606872558,
        10.060590324401858,
        10.754881568908694,
        12.286026649475097,
        8.994016792297366,
        6.0190768051147465,
        6.837534309387209,
        7.194523750305176,
        8.910070518493654,
        10.120466178894041,
        10.316228370666504,
        9.33072469329834,
        9.996024162292478,
        12.419366416931155,
        10.267408393859863,
        10.607010292053225,
        11.698041999816894,
        10.943069557189943,
        8.731853782653808,
        8.843366325378419,
        9.003064094543458,
        6.490788261413574,
        7.772042457580568,
        8.312946479797361,
        8.529307975769044,
        6.409005500793455,
        7.894549720764161,
        9.490013206481933,
        8.671268882751463,
        7.956771507263184,
        9.461410423278807,
        7.701300315856933,
        10.239550910949706,
        10.997413673400878,
        8.359080329895022,
        7.718066474914551,
        10.161075279235838,
        9.256973304748534,
        9.401632637023926,
        9.124588081359864,
        8.932996681213378,
        10.551284675598147,
        9.440775337219236,
        11.736249397277831,
        7.048083793640139,
        4.335139350891112,
        7.925097999572753,
        11.52952869415283,
        8.81987441253662,
        6.7501075668334956,
        6.1839062881469715,
        6.122244483947753,
        5.747424690246582,
        5.727908058166506,
        11.008639732360841,
        10.445414665222167,
        8.386936180114745,
        11.219976051330566,
        10.346624137878418,
        10.263462913513184
      ],
      [
        8.744432640075683,
        9.846649482727052,
        10.750977546691892,
        6.276574699401856,
        5.308365058898925,
        8.35289218902588,
        13.431199592590332,
        12.282341728210447,
        7.6582683334350605,
        6.218870307922362,
        8.249069023132325,
        10.140092826843262,
        11.048958198547364,
        6.131329887390137,
        8.761453651428223,
        8.655303535461428,
        7.2516417465209955,
        7.914873420715331,
        9.562921333312989,
        10.13717343902588,
        8.804258613586427,
        9.20736289215088,
        11.908038444519043,
        7.381256904602051,
        6.530839546203612,
        8.612659721374513,
        9.202369834899905,
        7.893380363464356,
        10.727492332458496,
        12.936213935852052,
        8.399700584411619,
        6.539555122375489,
        6.324490791320802,
        9.9204769821167,
        8.15718059539795,
        9.433997001647949,
        10.912122840881345,
        8.466236335754395,
        10.5827063369751,
        9.936261619567873,
        8.562473258972169,
        9.08269623565674,
        10.862924613952636,
        7.142072395324707,
        8.500811683654785,
        9.593472785949707,
        9.175014274597167,
        6.565349555969238,
        10.218839363098144,
        7.549499183654785,
        8.676709938049317,
        9.319669059753416,
        9.384245246887208,
        7.80751718902588,
        6.619001579284667,
        8.867209587097168,
        10.675889610290525,
        8.674105873107912,
        8.893554039001465,
        9.80170224761963,
        8.864007301330567,
        10.193749076843261,
        7.968929496765135,
        9.749797767639159,
        9.000230979919433,
        8.991896476745605,
        7.608189598083495,
        7.877888160705567,
        10.947876426696777,
        13.014842826843264,
        8.435112876892092,
        3.5074962158203125,
        7.894423469543458,
        9.503900825500487,
        10.13879096221924,
        8.258160972595213,
        7.092015678405762,
        8.012927864074705,
        10.318531654357912,
        5.541667747497559,
        8.906567878723145,
        11.768761894226074,
        9.514750801086425,
        9.597665367126467,
        11.783206199645996,
        9.30123783111572
      ],
      [
        8.544688774108884,
        8.046117927551272,
        12.783237159729005,
        10.437893379211424,
        8.224839332580565,
        8.566447257995605,
        11.851626167297361,
        10.850951179504392,
        8.441111076354979,
        3.944112541198731,
        5.861204399108885,
        9.822592155456544,
        11.263588523864748,
        5.5050853347778315,
        6.250377281188964,
        6.281548225402833,
        6.622168403625487,
        9.804956855773924,
        14.066466560363772,
        9.933433174133299,
        10.78106470489502,
        9.62999047088623,
        6.27269651031494,
        4.558671852111814,
        9.081713676452637,
        10.689131477355957,
        8.813101570129394,
        5.622618385314944,
        7.009650398254394,
        7.587313606262207,
        6.090289085388186,
        5.6774906845092765,
        7.063410758972168,
        10.211458534240723,
        8.789099098205568,
        11.168678520202636,
        12.06232874298096,
        10.627188240051268,
        8.602513404846192,
        7.220461647033691,
        5.734899223327638,
        7.905870872497559,
        10.102716239929201,
        5.27402744293213,
        8.61465682220459,
        10.274532539367677,
        11.536417121887204,
        12.226430305480957,
        11.951315391540525,
        11.57045008087158,
        10.058718452453611,
        9.541250495910646,
        6.965005897521973,
        9.090972938537597,
        5.772986686706542,
        6.461652305603028,
        8.549225456237792,
        11.73538190460205,
        8.887872398376466,
        10.226278114318848,
        7.714536506652834,
        6.528040550231935,
        5.355321662902831,
        6.451951850891113,
        8.697132514953616,
        9.34960343170166,
        9.239325630187988,
        9.02926599884033,
        11.05081699371338,
        10.137013771057127,
        8.059620018005369,
        4.645352180480955,
        5.283948554992676,
        6.122949363708496,
        8.104445198059082,
        8.679259590148924,
        9.367420936584473,
        12.536554023742674,
        10.410052085876465,
        7.0256881790161145,
        7.841538612365724,
        10.584062995910642,
        8.592128913879392,
        9.437064201354978,
        10.454132118225097,
        7.442371360778807
      ],
      [
        7.40453998565674,
        4.4845685653686544,
        10.383261665344236,
        10.920291496276853,
        8.958427925109863,
        8.699866706848145,
        10.619251411437986,
        9.452315040588381,
        8.850343864440916,
        5.810589256286622,
        7.681616172790527,
        8.390435417175294,
        10.876683586120606,
        7.08447646331787,
        6.554592979431153,
        7.2851831893920895,
        8.186866859436037,
        10.326569999694826,
        13.034477790832518,
        12.685233131408694,
        9.574298011779785,
        5.205265388488769,
        6.207611320495605,
        8.698191871643068,
        11.528776985168456,
        10.81300691986084,
        8.024149635314942,
        8.516433280944824,
        7.238429481506348,
        5.926662132263182,
        7.67870258331299,
        6.430570457458497,
        5.527897865295408,
        11.695061836242676,
        9.486629219055175,
        9.455430976867675,
        11.892450065612792,
        9.061103065490723,
        5.298045463562012,
        5.234066795349122,
        10.075907524108885,
        11.257221122741697,
        9.876564750671385,
        6.77262348175049,
        7.532548362731934,
        8.885555549621582,
        11.558776100158692,
        10.60835143280029,
        10.665810188293456,
        12.66732427215576,
        9.541409828186033,
        4.689426307678222,
        5.6136156387329095,
        9.462915962219238,
        7.318967445373534,
        7.239128288269043,
        10.26725431060791,
        8.790768089294431,
        10.52267337799072,
        8.356790733337402,
        6.391934562683105,
        3.0177723541259773,
        6.889477958679201,
        11.084438468933108,
        14.002470283508302,
        8.925210853576658,
        8.532853584289551,
        9.361143196105957,
        9.638237937927244,
        7.6112733230590806,
        7.6300701217651365,
        8.685527442932127,
        6.6217223739624025,
        7.3020219497680685,
        7.998636405944822,
        7.999797142028807,
        12.149130348205567,
        12.935469276428222,
        9.857917076110837,
        7.226059394836426,
        8.244453178405763,
        10.488429664611814,
        7.167098808288575,
        9.95948154449463,
        11.649878486633298,
        8.909829856872559
      ],
      [
        11.779006660461427,
        4.65930625152588,
        4.744472831726075,
        7.230698066711426,
        9.40170046234131,
        10.685679924011232,
        9.539410041809084,
        5.864099739074707,
        7.3942173538208,
        9.347141288757324,
        10.528590339660646,
        9.332407752990722,
        9.072008003234863,
        5.929522605895997,
        7.688249656677247,
        8.971318885803221,
        10.406668922424316,
        8.352193000793456,
        7.992518226623535,
        10.116100379943848,
        7.735785346984862,
        5.524772148132325,
        5.939531852722169,
        10.394309379577635,
        11.730321144104003,
        5.119065254211428,
        4.562261726379393,
        10.66538483428955,
        8.021673027038574,
        8.237133293151857,
        10.166158378601075,
        9.849312095642091,
        9.06223621368408,
        12.12342588043213,
        8.866605628967285,
        8.535582054138182,
        10.955799613952635,
        8.320516807556153,
        7.485510032653806,
        6.39628215789795,
        8.386496345520019,
        13.698788825988771,
        10.021889854431151,
        8.465864143371583,
        6.554476783752442,
        9.720803794860842,
        11.854288581848145,
        9.013561210632325,
        6.7133301010131845,
        10.789694068908691,
        10.076928718566894,
        7.400075065612793,
        7.85966561126709,
        10.86173171234131,
        11.874122352600097,
        11.83694049835205,
        11.314583808898924,
        6.982120521545411,
        10.632140327453612,
        10.298825813293455,
        10.57728118133545,
        8.625626258850097,
        8.117307899475097,
        9.501117347717283,
        11.94199979400635,
        8.122999214172363,
        7.158685585021971,
        5.985196861267092,
        5.722612709045411,
        5.684082344055177,
        8.145174140930173,
        8.243064643859864,
        9.981131660461426,
        11.833019813537597,
        9.800843635559083,
        9.742807640075682,
        11.970006172180177,
        8.153259574890136,
        4.755410804748536,
        4.711703964233401,
        9.249220893859864,
        8.75420400238037,
        10.364658882141114,
        11.422248664855957,
        10.60945157623291,
        8.02764836883545
      ],
      [
        9.367995887756347,
        5.155952278137207,
        4.911223091125489,
        8.860385261535644,
        8.105784614562989,
        10.01042099761963,
        10.105969657897951,
        7.6988290939331065,
        5.666339653015136,
        7.068433189392088,
        8.821816398620605,
        5.678861717224123,
        7.652492698669434,
        6.466946052551268,
        7.258106529235839,
        9.52909008026123,
        12.833124397277832,
        9.895921272277832,
        9.048473136901855,
        9.778368629455567,
        7.787197425842287,
        3.634624465942384,
        4.415070655822753,
        9.291761695861815,
        7.816592750549315,
        5.484960105895997,
        7.359241767883301,
        11.993581382751465,
        11.30061483001709,
        12.80269995880127,
        12.858723762512206,
        11.208029319763185,
        12.100792686462402,
        14.114959754943847,
        12.154797233581544,
        10.871082038879393,
        8.825208015441895,
        7.470493614196776,
        10.774635536193848,
        8.44951834869385,
        8.99708535003662,
        10.111843513488772,
        8.710220191955564,
        9.942534904479981,
        9.7727593460083,
        11.370505699157714,
        9.643358100891113,
        8.623783424377443,
        5.867023445129394,
        8.48340454864502,
        8.738523338317869,
        9.10960681915283,
        11.111988471984866,
        12.621173652648928,
        11.465149635314944,
        13.3683257522583,
        10.929914665222167,
        7.239318824768066,
        8.642645515441895,
        9.10507202911377,
        13.588171760559081,
        11.59236920928955,
        11.177056709289552,
        7.057523078918457,
        7.728850914001466,
        5.932637535095214,
        8.598099632263185,
        8.563969551086426,
        6.013800910949705,
        7.052965202331542,
        7.403247825622557,
        5.757948066711425,
        10.89501749420166,
        11.15039609527588,
        9.601004661560058,
        9.403215858459472,
        7.823742408752441,
        5.941531455993651,
        5.297170310974121,
        7.8057534255981444,
        10.916457710266116,
        10.092181037902833,
        10.907183662414553,
        10.344776451110839,
        11.951389335632324,
        9.219877632141113
      ],
      [
        8.904876365661622,
        6.930556907653809,
        8.265902214050293,
        10.332482933044432,
        10.276839851379393,
        9.09660083770752,
        9.063188362121583,
        10.02138040924072,
        7.506307334899901,
        7.804507026672361,
        8.428439750671387,
        7.074418952941894,
        8.32172873687744,
        8.7096810836792,
        8.601143898010253,
        9.409209632873534,
        12.258432167053222,
        8.913226066589356,
        10.504383338928221,
        9.587191658020018,
        8.809967231750488,
        6.511281074523925,
        7.188068153381348,
        7.436285636901857,
        9.598395484924318,
        9.0089693069458,
        10.356305976867674,
        10.069098640441894,
        9.00533080291748,
        8.108863487243653,
        9.133928565979005,
        6.696453437805175,
        6.8489640426635745,
        10.763100837707519,
        11.483115028381349,
        8.423817329406738,
        5.709114738464358,
        6.404294975280763,
        10.844482734680177,
        11.656161506652833,
        9.00070858001709,
        8.572334403991697,
        8.811239784240723,
        8.37757511138916,
        8.633731956481931,
        9.030867958068846,
        10.392827491760254,
        10.320648323059082,
        8.657452812194826,
        9.782966590881347,
        8.877484016418457,
        7.830921150207519,
        9.462201820373537,
        9.015044670104981,
        8.576509727478026,
        8.57212703704834,
        8.327212837219239,
        9.214369972229004,
        8.460786277770996,
        4.585121513366701,
        8.5403902053833,
        12.13716965484619,
        10.510852546691893,
        7.138958824157715,
        7.679481590270996,
        8.980490608215334,
        11.819366737365723,
        9.204619285583497,
        9.36203035736084,
        8.024568031311034,
        8.802725944519043,
        5.449131935119631,
        8.658515083312988,
        8.978119789123536,
        9.840427391052245,
        8.878552742004395,
        8.228763816833496,
        7.27845417022705,
        9.1381870803833,
        10.890869178771972,
        8.971971168518067,
        7.138007804870604,
        9.294053428649903,
        9.591646537780761,
        13.7243637008667,
        8.958783912658692
      ],
      [
        8.62039685821533,
        10.956825813293456,
        14.35525022125244,
        12.224552497863769,
        10.522693702697755,
        9.299253410339354,
        8.248548683166504,
        10.135911170959474,
        6.9494506301879895,
        9.098441658020022,
        8.372888923645021,
        7.988897163391115,
        10.301940696716308,
        12.894642845153811,
        10.16809102630615,
        11.827774162292478,
        13.187802467346192,
        7.533484489440916,
        6.591548622131349,
        7.40755735015869,
        7.721729545593263,
        6.354295036315918,
        10.244833992004395,
        15.262632102966307,
        11.357913978576661,
        9.324546699523928,
        6.749170860290526,
        7.037641258239745,
        6.275593055725096,
        5.102154472351074,
        6.452654502868654,
        4.266131263732909,
        3.9120507888793945,
        8.315732582092284,
        8.746143562316895,
        6.556744300842286,
        4.24625644683838,
        5.265001502990721,
        7.12312543487549,
        8.646641716003415,
        7.119162605285645,
        6.764459083557128,
        8.663433876037598,
        7.890599342346192,
        8.842147056579591,
        9.521593284606933,
        13.452015388488768,
        12.521356941223146,
        12.175497505187987,
        9.23834204864502,
        9.374524742126464,
        8.597437110900877,
        8.744934730529785,
        6.920758171081545,
        9.366291297912596,
        9.052889442443849,
        8.37955770111084,
        8.587051124572753,
        8.730777549743653,
        8.089943489074706,
        7.231849830627439,
        8.374443000793455,
        9.242839424133301,
        5.990525337219239,
        9.109540870666503,
        12.762244850158691,
        12.986345710754392,
        9.447851142883302,
        5.753307151794434,
        7.966838233947755,
        9.130460624694827,
        7.078430793762209,
        3.6931399002075196,
        8.306985496520994,
        7.199367500305176,
        7.710476722717285,
        7.848052055358885,
        7.648500495910646,
        9.264805778503415,
        11.023929603576661,
        10.124324531555175,
        9.60501848602295,
        9.430619606018066,
        10.570997261047363,
        13.169058418273927,
        10.952681221008302
      ],
      [
        8.40371932220459,
        11.62834940338135,
        10.65909415435791,
        8.340647468566893,
        9.026781776428223,
        9.738906394958498,
        9.31953868865967,
        9.276394874572752,
        8.753812110900881,
        10.04079207611084,
        7.091030647277833,
        8.943995323181152,
        8.798804145812987,
        11.98284004974365,
        11.149478309631348,
        10.337959999084475,
        8.244460472106933,
        7.826107902526857,
        6.869502891540527,
        8.917899604797363,
        8.65747415924072,
        7.973765052795411,
        9.861678916931155,
        10.884003349304201,
        11.202857398986815,
        7.306966606140136,
        5.429817665100096,
        5.4888387832641605,
        7.790766609191895,
        6.328947288513184,
        10.173128120422362,
        8.994689216613772,
        7.644444053649902,
        9.799876075744628,
        10.223864845275877,
        8.62932640838623,
        9.368939491271973,
        7.689669975280761,
        7.9155979995727535,
        4.931511756896974,
        7.751901039123535,
        12.863161415100098,
        10.608766548156737,
        7.130808021545409,
        10.91064562225342,
        11.91351992034912,
        10.681152870178224,
        9.179527427673342,
        9.72860034942627,
        5.893995399475099,
        9.678001014709473,
        12.633510108947753,
        10.80180216217041,
        5.943049613952638,
        6.955321907043455,
        9.810153358459473,
        10.774218803405759,
        7.076331260681151,
        10.17251197052002,
        10.228964271545408,
        5.907011482238769,
        7.569579719543455,
        6.2952375564575185,
        7.191229454040528,
        8.664097587585449,
        10.282864173889159,
        9.293312431335451,
        8.873624351501466,
        6.972856895446778,
        7.37232949066162,
        8.563664176940918,
        8.78130265045166,
        7.959022163391111,
        7.7431368484497085,
        8.657155906677247,
        9.605706825256348,
        7.862118385314943,
        7.849371864318847,
        8.454399879455565,
        7.904917579650878,
        6.933720390319824,
        15.518895133972165,
        11.77151128387451,
        9.610412406921387,
        6.496490226745607,
        7.430585121154785
      ],
      [
        8.825471168518064,
        10.453057533264158,
        7.9406342239379875,
        5.458301734924316,
        3.3160678176879888,
        7.816023185729982,
        8.788506843566893,
        10.13637284088135,
        10.553210762023927,
        11.683354499816893,
        11.614145179748533,
        9.238323204040526,
        7.658791999816895,
        8.56798398590088,
        7.43717343902588,
        6.6227965316772455,
        7.702845054626465,
        10.540210762023925,
        8.762324592590332,
        10.371607490539553,
        9.911407966613769,
        7.826596000671387,
        7.7986998214721694,
        8.681770133972169,
        12.31124517059326,
        10.545262733459474,
        9.594672889709472,
        8.92263941192627,
        11.207608055114747,
        10.39359300994873,
        9.601727531433106,
        9.944521766662596,
        9.372582099914553,
        9.748207344055174,
        8.415325569152834,
        7.752871803283689,
        7.063758537292479,
        7.76602136993408,
        10.137057533264162,
        7.03582592010498,
        12.201995903015138,
        11.928663063049317,
        11.416209129333495,
        7.6521898574829095,
        8.990246971130372,
        5.712990455627441,
        7.892612709045409,
        9.346513313293457,
        7.691068687438964,
        6.232915657043456,
        8.793571464538573,
        11.406066963195801,
        9.966623359680177,
        6.69687282562256,
        6.195372184753417,
        5.5177717361450185,
        5.542024238586425,
        6.556010765075683,
        10.993188758850096,
        9.118615028381349,
        8.419651863098146,
        5.993446449279787,
        10.73736876678467,
        11.393853218078611,
        9.638023872375488,
        7.7729312210082995,
        10.800342628479005,
        9.750832557678223,
        7.194190574645997,
        7.935031303405761,
        11.1432396774292,
        11.014618995666503,
        10.879740760803223,
        9.72734154510498,
        10.696934791564942,
        9.521158210754393,
        9.591016609191897,
        7.667879753112793,
        6.83517423248291,
        7.44151830291748,
        9.900063896179198,
        12.467361610412595,
        7.891725990295409,
        6.747956291198729,
        5.965892005920409,
        8.2538716506958
      ],
      [
        5.911811454772948,
        7.640857246398927,
        6.8510611190795885,
        9.101608253479004,
        8.338158256530761,
        6.898755134582519,
        7.094367057800291,
        12.667039009094239,
        14.177019508361816,
        12.155211830139159,
        11.428651100158689,
        9.674100044250487,
        8.006056159973145,
        8.481475166320799,
        5.624330070495606,
        4.537499656677248,
        7.3511731185913085,
        12.247524238586426,
        8.362149696350098,
        8.70147962188721,
        9.421888542175292,
        9.98908991241455,
        7.800598030090331,
        13.246232841491697,
        10.927624809265136,
        10.262429176330567,
        8.461289985656737,
        7.7735228500366205,
        9.896822334289553,
        12.571259925842284,
        9.822049446105957,
        7.6104119033813475,
        7.7066223526001,
        7.671367362976074,
        5.252139076232911,
        7.534552803039553,
        6.256882240295411,
        7.047254112243653,
        10.490818412780762,
        10.066681312561037,
        12.376883308410644,
        13.954827613830567,
        10.729204841613772,
        5.802312522888183,
        5.575471015930177,
        8.29183345794678,
        10.251470649719238,
        10.501726097106932,
        25.797643058776856,
        6.398246849060058,
        8.04076844024658,
        11.439792884826659,
        9.886774299621582,
        8.352550254821779,
        9.49483307647705,
        6.597816474914552,
        3.424968704223634,
        1.9270761184692375,
        7.9478545608520506,
        7.510088508605957,
        7.219305473327637,
        9.581819648742673,
        14.569750480651855,
        10.209096260070801,
        10.545016975402831,
        6.012963844299318,
        4.5882555313110345,
        8.970902442932129,
        12.919001228332519,
        11.938180992126465,
        9.188527381896975,
        8.763257514953615,
        9.883673271179198,
        11.171181892395019,
        7.942148857116699,
        9.678985191345213,
        9.265336738586427,
        9.335362007141114,
        9.454920051574707,
        7.799796714782715,
        7.262522026062012,
        11.168278755187988,
        11.484599311828614,
        8.48645252227783,
        6.597334999084474,
        7.9839274673461915
      ],
      [
        9.04737964630127,
        10.140657249450683,
        10.30393953704834,
        10.38837866973877,
        11.945176948547363,
        8.884140815734863,
        3.7022252120971686,
        6.218277397155763,
        10.353711601257324,
        8.53072145843506,
        9.107262992858885,
        11.59427890777588,
        8.32209152984619,
        10.176805274963378,
        8.478975349426271,
        8.360097007751463,
        9.5608823928833,
        10.833127052307127,
        7.16944245147705,
        5.868203224182128,
        8.396014518737793,
        10.292581825256349,
        7.941307304382324,
        9.760010215759277,
        10.670793006896972,
        8.697282295227051,
        5.256571159362792,
        6.907245765686035,
        11.042847679138184,
        12.26908394622803,
        9.260367012023927,
        7.35996295928955,
        8.854742515563963,
        8.173988960266115,
        6.202461448669432,
        8.506078758239745,
        9.965365669250488,
        8.427769371032717,
        8.478170249938962,
        9.282411079406739,
        9.298539085388185,
        9.940320991516113,
        11.528935096740724,
        7.3728866653442395,
        4.318629745483399,
        8.860805168151856,
        30.508934684753413,
        61.66156089019776,
        60.662903602600096,
        57.70678318023681,
        59.02092624664306,
        10.555033088684084,
        9.209715965270995,
        12.294112495422361,
        11.150375511169436,
        8.562644081115721,
        4.274478950500487,
        6.831103538513183,
        9.726712699890136,
        8.64022386932373,
        8.613631553649903,
        10.88464136505127,
        9.531789558410644,
        8.61778902435303,
        9.662605506896973,
        8.713464164733885,
        5.373394020080568,
        6.2624838638305675,
        11.486067848205565,
        13.831926124572753,
        9.07377643585205,
        7.277342308044432,
        8.416726234436034,
        9.02117816925049,
        6.224830711364746,
        8.353187370300294,
        7.979459770202638,
        8.490648841857912,
        8.768236045837405,
        9.537648307800293,
        9.211743583679201,
        10.780199302673338,
        11.603177742004394,
        7.232573036193848,
        5.006799491882326,
        9.161426429748538
      ],
      [
        11.190603324890137,
        13.634159934997559,
        9.677522026062011,
        11.133622810363768,
        12.866414741516113,
        9.85159854888916,
        3.6894578704833982,
        3.226297126770019,
        8.208282524108888,
        9.558317054748535,
        10.79715734100342,
        10.59933277130127,
        9.523504371643064,
        8.836763282775877,
        11.255815711975096,
        13.680043006896973,
        12.748911720275878,
        7.534479927062989,
        5.267148323059082,
        5.019134498596191,
        7.170597297668458,
        8.784945198059084,
        5.934363990783691,
        9.247591163635256,
        8.420516700744628,
        7.382791191101074,
        4.233010749816895,
        6.596501960754395,
        9.37322097015381,
        8.770263481140137,
        5.982214179992674,
        6.4813337478637685,
        9.535877983093261,
        8.743917915344237,
        7.935248619079591,
        8.810513191223144,
        8.865685737609866,
        10.841510719299315,
        12.02510391998291,
        10.714255165100099,
        7.624839424133299,
        7.78319133758545,
        10.641506309509275,
        6.884623420715332,
        2.975396270751954,
        7.509001625061035,
        10.957795188903809,
        63.743416175842285,
        61.285777748107904,
        59.55396262359619,
        62.39941800689698,
        59.88899723052978,
        8.509479698181153,
        10.014776786804198,
        12.624815635681152,
        11.072503486633302,
        7.975022956848143,
        8.345381019592285,
        11.012243293762207,
        9.654901710510252,
        11.374343742370606,
        10.545202445983886,
        7.386393211364748,
        9.48784511566162,
        11.552285743713377,
        12.439771690368651,
        10.593226783752442,
        11.058819755554197,
        11.705145881652832,
        11.615040031433104,
        10.540926612854005,
        8.737426765441896,
        5.514319541931151,
        5.727270683288573,
        4.884962348937989,
        6.168033058166504,
        7.704632301330566,
        8.758705940246582,
        11.89348316192627,
        11.176067420959473,
        10.860077751159668,
        8.814515510559083,
        6.294559211730956,
        5.3003793258667,
        6.0519838180542,
        11.714659767150877
      ],
      [
        10.791273994445802,
        11.27000919342041,
        10.440244911193847,
        10.004907051086427,
        9.447418251037597,
        10.46245206451416,
        7.758714103698729,
        7.542851936340334,
        9.07379694366455,
        11.888477439880369,
        8.082092735290527,
        8.479627464294431,
        7.690303962707517,
        10.263165199279783,
        9.289037452697755,
        10.031949317932131,
        12.517834846496584,
        10.076774299621583,
        8.01821007537842,
        7.790794868469238,
        9.195286598205566,
        7.960000358581542,
        7.776958366394041,
        10.89022566986084,
        10.141683052062987,
        8.63432103729248,
        3.8677349624633806,
        5.841523124694824,
        10.028009773254396,
        10.1690336227417,
        7.18908162689209,
        7.637981986999513,
        9.851209724426269,
        9.131356224060056,
        5.298753196716309,
        7.1050531387329094,
        9.244449073791504,
        7.449728279113771,
        12.178346534729002,
        11.615788398742676,
        7.6370125350952165,
        5.31027791595459,
        9.074677406311036,
        9.340698539733886,
        8.330580085754395,
        7.785623512268067,
        9.912244606018067,
        9.777613151550291,
        60.95574308013916,
        61.454093238830566,
        60.07696433258056,
        59.068842994689945,
        58.14118961334228,
        8.324287940979005,
        9.768983894348146,
        10.358611808776857,
        10.346003120422363,
        9.684674324035644,
        11.386678947448729,
        11.854463203430175,
        8.683280616760253,
        8.748462120056153,
        8.879124473571778,
        10.472173866271973,
        10.465150550842285,
        10.917991966247559,
        12.326456977844238,
        10.296555122375487,
        7.783430885314942,
        8.972065437316893,
        11.52462798309326,
        9.933603202819825,
        6.4466490554809575,
        7.626207176208496,
        8.25404944610596,
        7.561777854919434,
        4.792242744445801,
        7.2846134109497065,
        12.024904579162598,
        13.497716041564942,
        8.762613319396973,
        7.3378231277465815,
        9.681589363098144,
        6.198168785095213,
        7.177058158874512,
        12.145428047180175
      ],
      [
        7.376735221862795,
        8.313051795959474,
        7.613301109313966,
        7.174374565124513,
        11.341120368957519,
        7.930771293640138,
        3.972794807434081,
        7.961188591003417,
        8.558356971740722,
        10.650425468444823,
        8.63531713104248,
        5.855954917907717,
        5.359333427429199,
        8.745035453796387,
        5.326480827331544,
        9.505195564270018,
        13.439898506164553,
        10.85076346588135,
        8.395874961853025,
        8.67345694732666,
        9.32236893463135,
        11.293995826721194,
        9.3639820022583,
        8.37797568511963,
        10.489469520568846,
        11.026148323059083,
        10.25923184967041,
        9.184302268981934,
        10.922800468444827,
        9.452442756652832,
        9.051189811706543,
        9.890326301574706,
        7.651363563537597,
        8.04479824066162,
        8.965303596496582,
        10.669028282165527,
        10.474643363952637,
        6.56356093597412,
        11.67787432098389,
        11.342232246398925,
        8.50729990386963,
        7.459692283630371,
        9.259338600158692,
        7.105605720520018,
        9.101835456848143,
        9.164299247741699,
        9.520383537292481,
        7.359680091857911,
        59.409843666076654,
        61.14462052154542,
        62.48420121002198,
        58.37592136383057,
        58.16266513824462,
        61.23251023101807,
        9.31026686859131,
        9.056657188415528,
        8.700636528015139,
        7.890365379333495,
        8.210612419128417,
        11.347374656677246,
        10.063087196350097,
        5.965604026794434,
        7.834496360778807,
        6.103496925354005,
        4.243741096496581,
        8.715270057678225,
        11.181675331115724,
        10.945159889221191,
        9.55005136871338,
        6.4620524215698225,
        6.755529426574707,
        6.86373345184326,
        8.007344383239747,
        9.74798850250244,
        11.84488809967041,
        12.334709800720216,
        10.081357139587404,
        7.691566154479979,
        10.680367683410644,
        14.331556205749514,
        9.521741569519044,
        8.078259544372557,
        9.812180458068848,
        7.833887519836427,
        6.279145545959473,
        4.847187812805174
      ],
      [
        9.819105339050292,
        9.152574562072754,
        6.178758827209471,
        6.505195411682131,
        8.234913688659667,
        9.658910087585447,
        6.830359626770019,
        6.83001616668701,
        5.667306999206545,
        6.7854854812622065,
        6.844087486267089,
        8.781881843566893,
        8.944730995178222,
        9.405834983825685,
        7.820242805480959,
        9.451041069030762,
        11.423333686828613,
        9.052133491516113,
        7.764888175964355,
        8.390200096130371,
        10.62161026763916,
        11.446906440734864,
        10.047215766906739,
        7.728358467102051,
        9.937775398254395,
        12.61838126373291,
        11.119116340637206,
        10.657954780578613,
        12.848513160705565,
        12.833380332946778,
        10.475550468444823,
        8.335566688537597,
        5.793094261169433,
        9.152353691101077,
        10.153053504943848,
        12.568941062927244,
        10.817046607971193,
        7.417928352355958,
        7.960610237121582,
        8.244798606872557,
        7.201460456848146,
        7.629774360656739,
        9.606828453063965,
        7.039835823059082,
        9.507973289489748,
        8.824592826843261,
        6.810074516296389,
        7.060004707336425,
        7.476278404235842,
        59.3599846572876,
        60.9100506362915,
        63.03143863677978,
        61.74736588287354,
        58.95066593170165,
        59.61401078033447,
        59.11386893463134,
        8.018243095397949,
        8.374091346740723,
        7.100923545837404,
        11.768469429016115,
        10.44227060699463,
        7.379906517028807,
        8.736136756896972,
        3.1874867172241217,
        3.706598472595214,
        8.008078865051267,
        12.335137397766111,
        9.786164176940918,
        9.69155071258545,
        6.3481225051879875,
        6.3477335739135725,
        9.192372093200682,
        11.8585355758667,
        10.65930234527588,
        12.5430093460083,
        12.434504035949708,
        11.143203285217286,
        8.265510200500486,
        10.072388542175293,
        14.559297294616698,
        9.524811470031736,
        8.452529121398925,
        11.249547340393065,
        12.790955497741699,
        7.808552940368653,
        4.4530534439086935
      ],
      [
        8.684941062927244,
        8.983143745422364,
        8.26666368865967,
        10.473944480895995,
        10.509683982849122,
        8.73499164581299,
        8.910743324279785,
        7.389774192810059,
        8.5490591506958,
        7.525434211730957,
        8.827168922424317,
        10.718965156555175,
        10.381683479309082,
        8.281168754577635,
        9.054608268737795,
        8.43776851654053,
        9.221980812072754,
        11.082839698791503,
        6.4311213760375985,
        5.575788764953614,
        8.709177742004393,
        11.213736305236814,
        9.843670234680175,
        8.862752388000487,
        6.1917844314575206,
        8.604689292907715,
        9.428581611633302,
        7.409474494934081,
        9.444457389831541,
        12.62608666229248,
        9.738035774230958,
        7.468753837585449,
        8.221898200988768,
        10.026600639343261,
        9.83880837249756,
        9.626487464904784,
        9.625722831726073,
        8.334663032531736,
        8.808349784851075,
        8.158266044616699,
        8.962629280090333,
        10.841867637634277,
        7.402434013366701,
        10.573971778869627,
        9.424994682312011,
        8.894962348937987,
        9.38061742401123,
        8.094657569885253,
        8.213941322326662,
        8.085531654357908,
        8.683476905822754,
        12.370868324279783,
        64.74934464263916,
        60.02662290191651,
        58.51318039703369,
        60.21881351470947,
        60.180303810119625,
        8.486182853698729,
        8.597366081237794,
        11.85949083709717,
        9.232028846740725,
        8.822695838928222,
        10.87594167327881,
        5.377755393981936,
        4.050992248535156,
        8.698762626647948,
        9.422135276794435,
        8.968274894714355,
        8.395224632263183,
        9.644957054138182,
        8.510448570251462,
        11.107318183898926,
        10.537284004211426,
        7.573929191589357,
        8.360714042663574,
        8.84258731842041,
        8.718531059265139,
        7.642172706604004,
        10.367361442565919,
        12.22907120513916,
        9.121699714660643,
        5.356490913391113,
        7.667271888732909,
        8.965437507629396,
        9.066094139099121,
        8.733753471374513
      ],
      [
        7.0679417495727535,
        9.414501502990724,
        11.252884452819824,
        9.10402434539795,
        9.516794425964356,
        8.740954444885254,
        10.07826377105713,
        10.217392768859863,
        10.029857933044433,
        7.642809913635254,
        8.770508171081545,
        8.73125154876709,
        6.194947425842287,
        7.755376335144042,
        9.742660011291505,
        8.962727882385252,
        7.382282890319825,
        8.460589042663575,
        6.979473716735839,
        7.744636878967285,
        9.544303550720215,
        9.556236000061034,
        8.985725502014162,
        4.619847587585447,
        5.3487662582397455,
        6.625231803894042,
        4.6633624496459944,
        5.411266975402832,
        9.960158714294433,
        11.322733283996584,
        8.943777183532717,
        11.176693428039549,
        11.076068794250489,
        10.811106300354005,
        10.13674811553955,
        8.873112998962402,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.758072349548339,
        13.28454114532471,
        11.769032737731933,
        10.617713890075684,
        12.529544654846191,
        10.845822242736816,
        12.537180122375489,
        7.8428942031860345,
        7.166121665954591,
        10.890878700256348,
        10.913851860046385,
        7.688717521667481,
        8.336721031188965,
        61.60228855133056,
        57.22687924957276,
        60.92044803619385,
        58.92143265533447,
        7.581513725280761,
        7.77693514251709,
        9.629008155822753,
        9.115399864196778,
        7.795688056945799,
        8.131442298889162,
        9.60322808074951,
        7.50240225982666,
        7.74731017303467,
        4.984124610900878,
        4.508400215148924,
        7.696210655212404,
        9.873523811340334,
        8.972114875793459,
        6.807647743225097,
        7.964195167541504,
        9.050973442077638,
        6.656982795715333,
        7.622343269348143,
        9.5147066116333,
        10.26868854522705,
        9.931368354797364,
        9.330643974304198,
        7.360662864685057,
        6.802827659606933,
        7.938694923400881,
        11.86268531036377
      ],
      [
        8.579596824645996,
        11.686551918029785,
        12.531057426452637,
        6.9409368362426775,
        4.379071281433106,
        8.899794578552246,
        13.346951057434083,
        10.259063743591309,
        7.863628715515137,
        8.343259788513183,
        6.537201042175294,
        5.6750196304321285,
        6.254031761169433,
        10.970078987121582,
        13.43119036102295,
        10.966651420593262,
        5.009669715881348,
        5.875158378601075,
        7.676644432067871,
        8.215361106872557,
        8.662207649230957,
        7.347611381530761,
        7.937606727600098,
        6.36060442352295,
        9.541069511413575,
        10.010908119201659,
        5.105265068054198,
        7.0828254013061525,
        10.821979347229004,
        12.397618843078611,
        11.38057056427002,
        7.607037590026856,
        8.296011634826662,
        10.399366950988771,
        9.926125495910647,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        10.009337135314944,
        10.039118675231933,
        8.054065574645996,
        10.560908485412597,
        11.003221199035643,
        10.091079856872561,
        6.103120323181152,
        6.8174157791137695,
        10.539936897277833,
        9.821865013122558,
        8.976170768737795,
        9.79645534515381,
        7.914782341003416,
        5.56699127960205,
        60.22874094390869,
        61.165244972229004,
        57.37223050689698,
        7.972495735168458,
        6.771876838684083,
        8.924104927062988,
        10.9286948928833,
        11.748651496887206,
        9.4739211807251,
        9.822128883361817,
        11.142069007873538,
        6.5049255905151355,
        3.744823333740234,
        8.798186393737794,
        8.712168891906739,
        6.499413795471192,
        7.209338050842284,
        9.230888191223144,
        10.71187149810791,
        9.675112800598143,
        9.435218116760254,
        7.5320804519653315,
        7.819356437683105,
        12.356781517028807,
        13.11585994720459,
        12.415459968566893,
        8.084536979675292,
        6.487397972106933,
        10.966556327819823
      ],
      [
        9.46655754852295,
        12.34087422943115,
        11.708980171203613,
        6.395029487609865,
        5.456445732116698,
        9.586155464172364,
        12.338042930603027,
        10.540843391418456,
        9.758540260314941,
        9.97112749481201,
        9.604133369445801,
        4.888505622863768,
        8.140203163146971,
        11.763705726623535,
        12.266741035461425,
        6.444659294128417,
        6.613876869201661,
        9.749933891296386,
        12.325749397277832,
        10.305764747619627,
        16.828395668029785,
        51.16961405181885,
        57.83565189361573,
        10.469406562805176,
        12.620479270935057,
        7.373931968688964,
        3.8963182678222665,
        8.751819099426271,
        11.165813667297364,
        10.079072120666503,
        6.170002708435057,
        5.012022407531738,
        6.189080802917481,
        9.39434152984619,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.314944908142088,
        8.55501749420166,
        7.396826576232911,
        7.110343223571778,
        6.63287052154541,
        8.72788497161865,
        11.185647041320799,
        11.076461280822755,
        9.331820365905763,
        8.671271492004394,
        9.15388028717041,
        9.569228080749511,
        8.385004844665527,
        8.170978599548341,
        61.28141623687745,
        58.676224220275884,
        59.502693397521966,
        8.645943489074707,
        7.541917533874511,
        12.145191703796385,
        11.59701372528076,
        8.393689262390136,
        9.7047229385376,
        11.112861656188965,
        6.620749961853029,
        6.258446708679198,
        8.838576820373536,
        11.098220100402834,
        11.240488960266113,
        12.225825370788574,
        10.267418846130369,
        5.804252388000489,
        5.51083959197998,
        7.973461296081542,
        7.500554740905763,
        5.730320732116699,
        7.8124819869995115,
        9.779184928894043,
        7.706851310729981,
        5.552183509826662,
        6.057118461608887,
        7.99996605682373
      ],
      [
        8.01192276763916,
        8.271713218688966,
        8.1611693649292,
        6.133907264709471,
        4.3902553024292,
        8.386418281555176,
        10.814592308044432,
        9.475598335266113,
        6.75355690765381,
        6.574273399353025,
        9.011726936340331,
        8.96843972015381,
        10.356251808166505,
        9.865890266418457,
        7.769736129760744,
        5.356454582214354,
        7.467555763244629,
        11.36922780609131,
        45.54150876617432,
        55.44025131988525,
        57.008890907287594,
        58.19032932281493,
        57.14465561676025,
        62.02376596832275,
        61.42258109283446,
        60.71386142730714,
        8.376540031433105,
        11.684741020202637,
        12.005169929504394,
        11.355719062805175,
        8.044073539733887,
        3.915613960266114,
        6.426660995483399,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.631617485046387,
        8.608403038024903,
        8.40880866241455,
        9.034546424865724,
        7.903757835388184,
        6.691297096252441,
        6.273955299377441,
        8.196264991760252,
        8.86394359588623,
        9.552127693176267,
        9.328740913391115,
        9.628332176208495,
        9.594370811462404,
        38.10925464630127,
        59.27714908599853,
        7.636890602111816,
        6.98809061431885,
        13.50216477203369,
        12.406141853332521,
        8.25944278717041,
        7.065439369201659,
        9.743320808410644,
        8.596408714294434,
        7.179280952453613,
        9.734174888610838,
        11.503905372619627,
        11.748600425720213,
        10.947616035461426,
        10.531656227111817,
        6.423918190002443,
        4.599379341125488,
        5.9438311080932635,
        8.355814628601074,
        6.808815086364746,
        8.801739570617677,
        9.18476668548584,
        5.6373494033813465,
        8.735283042907717,
        8.47939461517334,
        8.39813465118408
      ],
      [
        8.777507972717284,
        7.511105659484862,
        9.268745491027833,
        8.660951179504394,
        6.281784645080567,
        8.564610328674316,
        10.31951963043213,
        10.299507164001465,
        6.726173332214355,
        6.662486885070802,
        8.499275627136228,
        8.400054649353027,
        12.938775764465333,
        14.42254630279541,
        8.879735023498533,
        8.603848838806151,
        40.317929176330566,
        48.65872983551025,
        53.24861637115478,
        58.6866418838501,
        59.246024513244635,
        58.29232999420165,
        58.358855567932125,
        61.37219327545165,
        62.12892417144775,
        62.139202003479,
        7.6552681198120105,
        10.85547743988037,
        8.15885233306885,
        10.030813758850098,
        8.735708351135251,
        7.518577751159668,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.299333488464356,
        9.2857700881958,
        9.927809944152834,
        8.58188190460205,
        8.311816154479981,
        5.78249404144287,
        10.837197273254397,
        9.207388252258301,
        8.307994804382325,
        9.663062675476073,
        9.00137978363037,
        8.937809837341309,
        8.527660865783691,
        8.554284858703614,
        8.688304374694823,
        8.034813865661619,
        9.531087532043458,
        10.970446205139162,
        8.857665061950684,
        8.291563102722169,
        8.158211982727053,
        8.930942924499512,
        7.949556648254394,
        9.316431312561036,
        9.551543815612792,
        10.571755287170411,
        8.995112281799315,
        10.59880605316162,
        9.094901695251465,
        3.3138124694824214,
        8.28402674102783,
        9.680027793884278,
        7.710344429016114,
        8.230189704895018,
        8.510994285583497,
        9.403459922790528,
        12.039496223449706,
        11.26088596343994,
        8.905446968078614
      ],
      [
        8.184291999816892,
        6.9429049148559585,
        9.254785102844238,
        12.490960929870603,
        9.246879112243654,
        8.58628200531006,
        10.89686403656006,
        10.06840616607666,
        11.059215248107911,
        8.297589118957518,
        8.212351661682128,
        11.06402242279053,
        15.195669258117675,
        23.068489021301268,
        24.5872317276001,
        37.027548713684084,
        40.899488441467284,
        50.615539833068844,
        57.40675504302979,
        60.82529514312745,
        63.37635050201416,
        62.12714005279541,
        59.833448219299314,
        61.08239542388915,
        61.30971060943604,
        63.59087125396729,
        7.258013557434083,
        9.193584388732909,
        8.412102989196775,
        9.308800270080567,
        9.896627845764158,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.456977714538574,
        9.083801429748533,
        9.422477363586424,
        8.072630043029783,
        8.53521170806885,
        12.464943611145017,
        12.020899971008301,
        7.109043067932127,
        9.768885765075684,
        10.101830787658692,
        5.695124275207519,
        5.048176261901855,
        9.309911949157716,
        9.946090995788573,
        9.460020362854003,
        10.314938880920408,
        9.85862854766846
      ],
      [
        7.502063255310059,
        7.724167945861815,
        8.404494529724118,
        13.001629539489748,
        11.195613624572754,
        10.212025184631347,
        10.462453926086425,
        10.273269569396973,
        9.459165046691894,
        8.245745246887207,
        9.29351912689209,
        10.278041130065915,
        16.282574180603028,
        24.37561600494385,
        29.26123723602295,
        37.028618690490724,
        43.95451206207275,
        56.117763038635246,
        60.78231516265869,
        59.17392246246338,
        61.70703556060792,
        60.98886344146729,
        60.235479774475095,
        61.18509165191651,
        63.56959629058838,
        64.92460746002197,
        7.1765740127563475,
        11.290742362976076,
        8.443699745178222,
        9.675970390319826,
        10.086601051330568,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.436146995544433,
        9.316562950134276,
        11.795805198669434,
        9.861166648864746,
        9.352624534606932,
        10.399193534851072,
        8.92182610321045,
        8.890088081359863,
        6.478075462341309,
        9.955511817932127,
        10.197118354797361,
        10.047218910217286,
        8.894389228820799,
        7.9748726272583
      ],
      [
        11.20372290802002,
        8.142096565246582,
        7.045461982727051,
        12.602629188537598,
        12.407319023132324,
        8.057536659240725,
        5.696362449645996,
        9.098335258483885,
        8.898133262634275,
        8.592476173400879,
        8.989217033386232,
        13.063430656433106,
        18.930307975769043,
        23.64399619293213,
        29.201091957092288,
        34.65695728302002,
        47.44175205230713,
        55.459458168029784,
        60.10690726470946,
        60.02840494537354,
        59.26996732330322,
        60.48009458160401,
        60.29858255767823,
        59.563462623596195,
        62.717593299865726,
        63.65071018218994,
        7.484109992980958,
        8.213489356994629,
        5.019809776306154,
        6.152448051452637,
        8.899491218566894,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.427587501525878,
        7.508283592224121,
        6.670179237365723,
        6.759138679504396,
        6.480030250549316,
        8.071163764953614,
        11.349157096862793,
        12.218408821105957,
        8.44508895111084,
        6.081738777160645,
        5.15688877105713,
        9.07529502105713
      ],
      [
        13.522539192199709,
        9.452341316223144,
        7.199499397277833,
        7.335409645080567,
        11.37674797821045,
        8.455224433898927,
        5.929276481628417,
        10.16652822113037,
        8.764069297790527,
        7.160922706604005,
        7.042222679138185,
        12.978691398620605,
        15.511727073669434,
        15.339661155700682,
        13.627753227233889,
        10.602370979309082,
        36.09051398468018,
        54.125361366271974,
        56.40307248687745,
        59.68642203521728,
        57.182067726135244,
        55.35275235748291,
        59.2759811782837,
        61.99824210357666,
        65.49938254547119,
        59.014458290100094,
        3.738423393249512,
        7.075143074035644,
        7.2804728164672845,
        3.5763235397338864,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        6.909270042419433,
        4.758461463928221,
        5.64477010345459,
        6.004391914367677,
        9.125941917419432,
        11.786550331115723,
        9.060829765319824,
        6.245235893249511,
        7.656357307434082,
        11.997139976501465,
        11.79193106842041
      ],
      [
        8.633340187072754,
        7.160974220275879,
        9.788042091369629,
        8.983038063049314,
        11.959877265930174,
        8.909054908752442,
        6.663444984436033,
        6.638860038757322,
        10.466996040344238,
        8.571180870056153,
        8.527245292663572,
        9.747553443908693,
        9.455832313537599,
        7.405623695373535,
        7.314940406799316,
        8.525867958068847,
        9.918515815734864,
        9.366780525207517,
        55.00819882965088,
        62.239352760314944,
        59.30224002838135,
        54.47915360260009,
        56.244435035705564,
        63.537332008361815,
        63.41513266754151,
        59.07935489654541,
        4.6084443130493185,
        6.2240426559448245,
        9.043162544250489,
        9.235877113342283,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.741812049865722,
        9.711500465393065,
        7.793843086242674,
        10.762099174499511,
        9.039854469299314,
        6.133327186584474,
        5.0105419540405265,
        12.788738777160646,
        10.651763755798342,
        9.0188635635376
      ],
      [
        3.828402297973632,
        6.0771183547973635,
        10.52634627532959,
        9.343234184265135,
        12.753843727111814,
        9.921919715881348,
        6.331556312561034,
        5.3251257858276375,
        9.154581947326662,
        9.687726936340333,
        13.90531359100342,
        15.758938529968262,
        13.072888008117676,
        9.273012565612795,
        9.81514261627197,
        11.67169100189209,
        13.33138175201416,
        12.946488380432129,
        55.399786048889155,
        61.26966104888916,
        60.095024696350094,
        58.37591806793213,
        57.354986427307125,
        59.96661734771729,
        58.69595432281494,
        58.84437808990478,
        5.523212165832518,
        7.380075202941896,
        7.883031654357911,
        9.957732337951661,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        10.4745064163208,
        11.480318351745606,
        14.21038974761963,
        12.493505256652831,
        10.295327156066897,
        7.446801994323732,
        11.005096656799317,
        9.884922813415528,
        11.257951667785644,
        6.37344017791748
      ],
      [
        6.78660475921631,
        6.742906745910645,
        9.210667976379394,
        7.631104331970214,
        8.544889915466307,
        9.315743202209475,
        6.908179481506348,
        5.996904090881348,
        11.13481566619873,
        10.161499000549316,
        11.398681953430177,
        11.9362767868042,
        11.851835197448729,
        9.709268623352052,
        10.383011543273923,
        8.258848747253417,
        8.96476425933838,
        10.371804191589355,
        9.356696784973145,
        56.93103006744386,
        61.853250099182134,
        60.95350394439697,
        60.611750038146965,
        58.76436667633057,
        58.36408652496337,
        56.82086197662353,
        8.12734008026123,
        8.600289085388184,
        4.470541801452637,
        3.994776557922364,
        8.410357505798338,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.306928657531738,
        10.377124931335452,
        10.483482215881345,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        6.799519004821779,
        8.787965919494631,
        13.751131538391114,
        9.816478981018065,
        9.59944588470459,
        6.619093437194824,
        6.160829017639159,
        8.257893791198732,
        7.839664344787597,
        7.03676552581787,
        9.515799232482912,
        10.061974800109866,
        7.588718620300291,
        8.456192588806154,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.929848411560059,
        10.623880073547362,
        12.11223900604248,
        9.055336830139161,
        9.341560676574709,
        11.161824745178222,
        8.481716133117676,
        9.448806266784668,
        4.168729576110842,
        6.662183723449708
      ],
      [
        10.290178382873535,
        7.823792488098142,
        10.213619636535647,
        9.855921546936035,
        8.240508277893067,
        7.2114676895141585,
        6.407785545349121,
        7.9707570877075185,
        10.92166625213623,
        8.843767448425293,
        6.955527458190918,
        9.456069587707518,
        8.989856330871582,
        7.760896919250488,
        6.867811988830567,
        9.33564234161377,
        10.493176460266113,
        8.418871238708498,
        6.771103309631346,
        53.172225929260264,
        56.868224342346195,
        62.39337363433839,
        64.4480065689087,
        57.115375526428224,
        56.115634498596194,
        61.905742225646975,
        14.17626767730713,
        13.188420310974124,
        6.816117805480957,
        4.243138496398927,
        9.372670585632324,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.938585105895996,
        10.735228538513184,
        8.605352928161622,
        9.710645164489748,
        6.840363990783693,
        7.548164024353028,
        9.202775337219236,
        6.464675926208496,
        4.689589607238769,
        7.027517662048339,
        11.826059074401854,
        9.26210047149658,
        8.77669620513916,
        8.097567466735839,
        11.553817283630373,
        11.875608741760253,
        10.60175691986084,
        9.21328588104248,
        9.591630073547364,
        6.529065010070802,
        7.97985585784912,
        6.922633522033692,
        7.437097541809081,
        7.085712257385254,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        6.177421211242674,
        8.486009773254395,
        8.676984657287598,
        8.267771644592287,
        7.536471305847169,
        7.400761253356933,
        9.678610054016112,
        10.240798881530761,
        11.754991493225099
      ],
      [
        6.5659144058227525,
        5.419411094665527,
        9.584381660461425,
        9.647740455627442,
        7.097798454284668,
        3.4873892898559564,
        4.176516113281249,
        6.921614723205565,
        7.5554744949340815,
        6.89830086517334,
        7.384708625793458,
        7.440702095031739,
        7.301899757385254,
        9.777077033996584,
        8.79140863800049,
        9.062105918884278,
        8.029376960754394,
        4.312242637634277,
        6.766838890075682,
        28.567113502502437,
        56.142929847717284,
        59.88971439361572,
        62.407396278381356,
        60.112817298889155,
        62.89547525787353,
        62.95405417633057,
        12.374630195617677,
        10.661991355895996,
        7.785229728698731,
        5.66348056793213,
        8.779748237609866,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.6631100692749,
        8.737031913757324,
        7.253506935119628,
        5.208864067077638,
        4.945563255310059,
        5.595676445007324,
        7.472596916198732,
        8.055014472961425,
        9.164772254943848,
        4.788349159240724,
        6.729233314514161,
        7.052272727966308,
        6.050949012756348,
        8.815809532165527,
        8.035337333679202,
        10.185457237243654,
        62.7731164932251,
        66.89322086334228,
        28.945579689025877,
        8.794465538024902,
        10.919810386657716,
        6.399957267761231,
        5.9450748367309565,
        7.393095726013183,
        10.665979499816892,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        7.327168952941896,
        10.53102663421631,
        8.158696266174317,
        6.778572441101076,
        8.433778755187987,
        9.179183433532714,
        6.4897358474731455,
        10.08577062225342,
        12.619753578186035
      ],
      [
        8.999402122497557,
        5.98186402130127,
        8.71666106414795,
        9.52556649017334,
        11.609993354797364,
        9.13188190460205,
        6.404780647277832,
        7.438004585266114,
        9.578369956970214,
        7.953870948791504,
        8.663558235168459,
        9.927361061096192,
        6.971452735900879,
        11.229942131042481,
        9.93301244354248,
        8.414411506652833,
        8.347935066223144,
        9.40072336578369,
        7.725377403259277,
        8.835034156799317,
        58.80160831451417,
        62.064843299865714,
        59.1658489151001,
        60.63986612701416,
        59.568794410705564,
        59.54610752105714,
        11.042653572082521,
        8.17946016693115,
        7.51229288482666,
        5.5188122177124015,
        8.831128883361817,
        11.333284797668455,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.833657249450681,
        8.925879554748533,
        10.012627052307131,
        7.3228557052612295,
        9.170535163879393,
        7.379680015563963,
        8.549824028015138,
        9.236669944763186,
        8.635838249206543,
        8.992631294250486,
        9.852482536315918,
        9.112609901428222,
        7.294372688293457,
        8.59318305206299,
        9.55937213897705,
        6.579774848937987,
        63.602998085021966,
        67.41341007232666,
        65.80577835845948,
        62.74554660797119,
        61.471029945373544,
        8.923575172424314,
        8.560828269958495,
        8.449640098571777,
        8.830559242248533,
        11.938051811218262,
        9.136783027648924,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.216542884826659,
        8.303743675231935,
        9.057305488586426,
        9.166613349914549,
        12.688558555603027,
        10.955479133605955,
        7.75685877227783,
        6.979414955139159,
        8.584647178649902
      ],
      [
        12.673711753845215,
        13.209636878967284,
        8.790216835021972,
        8.948718254089357,
        11.492312461853025,
        9.095145790100098,
        8.281644554138182,
        7.9315112075805665,
        9.530205207824707,
        6.871424324035644,
        7.805355354309082,
        7.5152157821655265,
        10.530560707092285,
        11.658983589172365,
        10.081131935119629,
        7.823490547180175,
        7.479624900817871,
        11.449943199157715,
        8.503965141296387,
        11.318035957336427,
        12.668486091613769,
        63.56423007965088,
        60.48009928131103,
        59.37784671783447,
        60.19285471343994,
        59.525228981018074,
        19.721687965393066,
        8.049307273864748,
        9.086613258361815,
        5.901870933532713,
        8.004297904968261,
        10.628687034606934,
        10.422123832702638,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.562293968200684,
        6.657375282287596,
        5.518754203796387,
        9.97342279815674,
        12.138709861755373,
        10.481234794616697,
        7.164313957214354,
        8.345296531677246,
        9.589395500183105,
        11.040421012878419,
        11.654790107727052,
        11.830775245666501,
        10.004579475402831,
        8.066419471740723,
        9.249652732849121,
        12.017001625061035,
        10.29182920074463,
        64.97422666168212,
        67.69069327545166,
        62.048621437072754,
        60.93668228912354,
        59.90797867584229,
        7.880412773132324,
        8.678715766906738,
        9.507169090270995,
        8.900897789001466,
        11.867527046203612,
        10.12066623687744,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        6.213853034973145,
        6.929614418029786,
        6.114034645080565,
        7.444825569152833,
        11.361571449279786,
        10.722332557678222,
        10.102350395202635,
        7.632393394470213,
        6.301542388916015
      ],
      [
        10.291543540954592,
        13.525406791687011,
        10.756455131530764,
        9.051622962951662,
        8.4786402053833,
        8.848526390075683,
        6.145738441467287,
        8.192807167053225,
        7.5876656417846675,
        7.493601722717287,
        6.678322059631348,
        6.172839607238771,
        7.132652183532713,
        9.237517112731936,
        9.427701835632327,
        8.95784952545166,
        10.016882865905764,
        7.539855430603026,
        10.130622367858887,
        11.248384819030761,
        11.030633049011229,
        55.65222623443603,
        58.519491493225104,
        59.473874488830575,
        61.34269486236573,
        61.623086585998536,
        57.75695847320557,
        9.317311683654786,
        8.091506248474118,
        6.38995532989502,
        10.263770851135256,
        12.252761604309082,
        9.576607170104978,
        8.239083381652833,
        9.141862525939942,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.46492360687256,
        9.160409233093262,
        7.1173412399292,
        7.500024101257324,
        10.017783393859865,
        11.145463874816894,
        8.052147010803225,
        6.97355673980713,
        7.496671684265138,
        11.057102409362791,
        13.031817817687987,
        11.838922767639161,
        12.216313255310059,
        9.411086799621582,
        6.815486793518065,
        9.0311509475708,
        10.522730857849119,
        65.3561234512329,
        64.55425290679932,
        62.002306373596184,
        61.41981449127198,
        59.592900489807136,
        60.10627902984619,
        61.79682108306885,
        8.287577735900879,
        7.992460990905762,
        8.073952110290527,
        8.421266258239747,
        8.940230278015136,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.536882835388184,
        7.847742820739747,
        5.705664543151855,
        7.010986640930177,
        12.31370342254639,
        11.349922874450684,
        12.94691823577881,
        11.0948607711792,
        6.81185652923584
      ],
      [
        6.549383247375488,
        10.60678475189209,
        7.193857841491699,
        8.243447181701661,
        10.272447685241701,
        11.271547828674315,
        11.154270591735838,
        12.348604454040526,
        9.45742519378662,
        10.875229530334472,
        9.895627326965332,
        7.727727241516114,
        7.624555671691894,
        8.676518852233887,
        6.751223731994628,
        8.247881813049318,
        9.017812294006347,
        10.263602561950684,
        11.600189140319824,
        7.8941606063842755,
        6.945139930725098,
        5.830195930480958,
        55.153888526916504,
        58.34446817779542,
        57.98682205963135,
        57.80186148834228,
        56.409657386779784,
        7.8541658554077145,
        6.448403160095214,
        5.185907203674319,
        8.3865911026001,
        11.413398735046385,
        11.184152671813965,
        12.03224195098877,
        12.069391380310059,
        9.138160636901855,
        5.307944145202637,
        5.679767478942871,
        8.601873558044431,
        9.368707283020019,
        10.473903373718262,
        10.170673042297363,
        9.550747901916502,
        7.563778007507324,
        8.6467858505249,
        9.035925193786621,
        11.191582633972168,
        10.452946815490723,
        7.66944538116455,
        8.721285697937013,
        10.859087608337404,
        7.696294898986817,
        8.455576225280762,
        8.672103828430174,
        10.785744300842286,
        10.397551811218264,
        5.509825141906738,
        6.9151287612915056,
        10.819129188537598,
        64.83969919586181,
        63.72970859527588,
        63.08255002593995,
        65.01738089752197,
        63.495068397521976,
        65.37509226226807,
        66.81914957427979,
        11.692534919738769,
        9.376359428405763,
        10.565836463928221,
        9.80849457550049,
        8.711168037414552,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        10.594026100158693,
        8.057662483215331,
        7.115692329406738,
        10.584962242126466,
        10.05797261810303,
        6.673094627380372,
        8.161036170959473,
        11.478851463317874,
        9.464493415832521
      ],
      [
        6.281857460021975,
        7.374591728210447,
        6.432087577819825,
        9.148609718322753,
        11.05002286529541,
        8.885918769836426,
        9.893332649230956,
        11.900243553161623,
        9.453117637634278,
        8.96889395904541,
        9.069382362365722,
        8.14906468963623,
        6.779444511413573,
        6.181343818664551,
        6.605960914611817,
        6.473780342102053,
        10.275948066711425,
        13.449120307922364,
        10.11588617706299,
        5.610638130187986,
        5.360789237976075,
        7.054169746398927,
        42.38041690826416,
        56.12970334625244,
        58.19516788482666,
        57.156576332092285,
        7.349299491882324,
        7.189901832580567,
        5.618740119934081,
        4.945734947204592,
        8.92394649505615,
        12.79373511505127,
        11.832091056823728,
        8.945948432922364,
        8.833795188903807,
        9.112925148010255,
        8.497102836608889,
        10.718141456604002,
        7.452840461730958,
        9.816790657043455,
        11.925516395568849,
        8.27198526763916,
        4.86926065826416,
        8.300134559631346,
        8.451517448425292,
        9.396836692810059,
        10.909802696228027,
        11.313985511779784,
        8.37410831451416,
        8.472657417297363,
        9.492946586608888,
        9.905202934265137,
        8.201259956359863,
        9.739852989196777,
        12.945329292297362,
        11.274767585754397,
        7.45637770843506,
        5.237024635314942,
        7.803959968566893,
        61.919763099670405,
        61.15579294586182,
        62.34261032867431,
        64.3259080581665,
        65.9283744277954,
        64.63626883697509,
        66.65014981842042,
        39.67480236053467,
        12.70077153778076,
        9.110158531188965,
        7.651084144592286,
        6.362145118713379,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        7.669242301940919,
        7.307041358947753,
        7.783751838684083,
        10.27147274017334,
        11.33013730621338,
        10.801049476623533,
        8.717741844177247,
        11.246141120910643,
        11.203591285705567
      ],
      [
        8.745330314636231,
        6.705486869812013,
        6.4005309524536145,
        10.993519081115721,
        12.475775077819826,
        8.927928199768068,
        8.636093711853029,
        9.791637748718262,
        6.19096425628662,
        6.461591209411623,
        9.015697975158691,
        8.470808235168455,
        7.781477699279783,
        9.347840309143066,
        7.714828407287598,
        9.100795021057131,
        11.618440635681154,
        10.116381736755372,
        7.864527259826659,
        4.2050293350219725,
        8.378693656921385,
        9.815246177673338,
        9.841638954162597,
        8.674649848937989,
        11.276492485046386,
        8.901284370422363,
        9.020486381530763,
        11.445150703430176,
        6.180170768737792,
        4.521797920227051,
        9.107906150817872,
        13.407620597839355,
        11.677614311218264,
        7.927903556823729,
        7.295954429626466,
        8.645866889953613,
        10.370315025329589,
        10.236080619812011,
        7.6776254196166995,
        8.743634086608886,
        9.272939643859864,
        6.290316230773925,
        7.370909523010255,
        10.428665199279784,
        10.476763984680176,
        9.089341239929198,
        9.78481908416748,
        9.641034858703613,
        6.718523689270022,
        62.76747586822509,
        63.13418491363525,
        9.406870201110841,
        7.301906120300295,
        11.054016426086427,
        11.799671165466307,
        9.060999183654786,
        6.515018577575685,
        7.160618873596192,
        7.952144966125488,
        63.65917636871339,
        63.7067850112915,
        64.78387509918213,
        65.4178469619751,
        62.58385788726807,
        63.93013724517823,
        63.372510414123525,
        10.114608680725098,
        11.98139376068115,
        7.017829231262205,
        7.965109367370605,
        9.102054786682128,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.307376228332519,
        8.211561073303223,
        5.7862711563110345,
        9.011068153381348,
        10.403605796813963,
        9.738828224182129,
        9.580918418884277,
        9.749590370178222,
        9.198201637268067
      ],
      [
        12.732550376892092,
        8.604815391540527,
        6.960488594055175,
        9.826086402893068,
        12.12982056427002,
        8.071918739318846,
        8.443470832824708,
        7.115451957702636,
        6.981401176452636,
        9.085372535705567,
        9.614508583068847,
        10.36848929595947,
        10.40233132171631,
        10.47338227081299,
        8.95907416534424,
        9.754231666564941,
        8.769361518859863,
        6.454118888854978,
        5.1077482376098615,
        5.086638633728029,
        8.46550350189209,
        11.03575771331787,
        9.085960090637208,
        9.531580574035644,
        10.435967750549317,
        9.199382865905761,
        11.625645912170413,
        13.709589302062987,
        9.27154076385498,
        8.135142768859865,
        10.97717359161377,
        12.019082771301271,
        9.936845466613768,
        9.08221704864502,
        7.420981086730958,
        6.523479606628417,
        8.129889381408692,
        9.209921531677246,
        9.144547203063965,
        8.59096900177002,
        6.294182426452636,
        5.754490348815917,
        10.556454414367678,
        13.06577306365967,
        11.592773796081545,
        10.349522315979002,
        6.988637077331543,
        6.1344450607299805,
        58.553440040588384,
        61.48692600250244,
        62.53780277252197,
        63.81653842926025,
        16.222561576843262,
        10.4964153213501,
        9.0392643661499,
        6.837597190856933,
        7.223630882263183,
        10.966376167297362,
        63.41827390289308,
        64.639965675354,
        64.37799088287353,
        65.04001108551026,
        62.55471866607667,
        62.99603049468993,
        60.97548807525635,
        58.81276609039307,
        7.101706520080565,
        7.9552645339965835,
        5.063117195129394,
        7.573470848083495,
        11.230716636657714,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.090121940612793,
        8.171107276916501,
        6.220282020568849,
        8.921168983459474,
        9.151608909606935,
        7.238069648742677,
        7.855444313049318,
        7.790407112121581,
        10.386231452941892
      ],
      [
        11.733318977355957,
        8.152232093811037,
        5.778136192321778,
        7.982318214416502,
        7.872573432922362,
        9.413547584533692,
        9.66754903411865,
        9.059960563659669,
        8.977974540710449,
        9.814255851745607,
        11.514779655456543,
        11.386714393615723,
        11.683333137512207,
        8.248687614440918,
        6.749933525085449,
        6.641763343811036,
        5.855480216979981,
        4.671653678894042,
        5.999225257873533,
        5.2419607467651375,
        6.76655657196045,
        10.697424629211426,
        7.898952781677245,
        9.401217811584473,
        7.645154304504395,
        8.352339958190917,
        9.937587104797363,
        10.998194435119629,
        10.244978340148926,
        11.984629035949709,
        8.613432518005371,
        10.333436897277831,
        10.152601676940918,
        10.553861961364746,
        7.949049324035645,
        8.279678535461425,
        9.040888893127441,
        6.1115942916870125,
        9.842998329162597,
        10.314053627014161,
        8.836985816955568,
        4.758678504943848,
        6.3765312118530275,
        10.75862442779541,
        10.10671105194092,
        7.022777091979982,
        6.8340745925903335,
        61.55255271148681,
        60.64551181793213,
        60.92474356842041,
        63.19603562164306,
        64.36765384674072,
        7.11780638885498,
        8.703087074279786,
        11.492764183044436,
        9.409589973449705,
        9.520406913757324,
        9.964365318298341,
        64.7521614151001,
        62.2481537246704,
        62.9196424331665,
        66.2168104019165,
        62.973648414611816,
        60.86885654449463,
        60.686805091857906,
        61.25706491851807,
        6.437527839660646,
        8.837585426330566,
        6.734498252868654,
        6.39193465423584,
        9.602652870178222,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        7.419720176696778,
        6.583004753112792,
        8.041126029968261,
        8.963592964172364,
        12.335361305236816,
        9.149221626281737,
        7.200909996032713,
        8.767969078063963,
        11.735437294006346
      ],
      [
        6.437004722595216,
        4.871836830139159,
        8.15842230987549,
        10.643121986389161,
        7.072932533264158,
        8.37583484649658,
        10.764976966857908,
        11.08696883392334,
        9.524172035217283,
        9.033740852355955,
        5.970070625305176,
        8.57698036956787,
        10.586301719665528,
        8.009220664978027,
        10.384525886535645,
        11.218001197814942,
        10.470009040832519,
        10.57345941925049,
        10.738779823303222,
        7.721082008361815,
        11.895088050842283,
        11.114916694641114,
        8.44417259979248,
        9.20893222808838,
        5.778908699035647,
        6.9569222793579115,
        9.933586814880371,
        9.89523139190674,
        8.882110069274901,
        10.287611671447756,
        8.619614402770996,
        7.056629463195801,
        5.241838760375977,
        6.436981254577638,
        6.766731620788576,
        9.880166297912595,
        9.748847694396972,
        8.041153007507326,
        11.371990669250486,
        12.00742942047119,
        7.618497093200684,
        5.252401069641113,
        5.926365821838381,
        8.00361563873291,
        10.705945030212405,
        9.267842247009277,
        8.391443824768068,
        61.942377479553215,
        60.03744223785401,
        62.5324709854126,
        8.989663078308105,
        13.228868400573731,
        12.149444633483888,
        10.81932935333252,
        10.283422035217285,
        8.254064231872558,
        7.0863908157348625,
        8.18335330963135,
        61.84602280426026,
        60.78497159576416,
        63.53030875396729,
        67.97616165924072,
        68.38639218902588,
        64.69056241607666,
        65.92301908111573,
        10.864118919372558,
        10.360651756286622,
        11.247692787170408,
        7.756270118713381,
        4.461941764831543,
        7.340451545715332,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.633044532775877,
        6.157892463684082,
        5.402021888732911,
        9.015719642639162,
        11.568053291320801,
        10.872862464904784,
        8.933508903503416,
        5.586986579895019,
        6.728540672302245
      ],
      [
        7.452736167907716,
        7.858159461975099,
        10.220108131408693,
        9.155702339172365,
        6.4190214614868175,
        6.479897804260254,
        9.259839210510254,
        10.87567307281494,
        9.131102943420409,
        8.191388160705564,
        7.21359595489502,
        6.392046989440917,
        8.562426109313964,
        5.7205489730834955,
        8.771234260559083,
        11.440844856262206,
        10.751180244445798,
        10.896376502990723,
        9.252143333435058,
        9.495358909606935,
        11.268687934875487,
        11.341106117248536,
        8.454969108581544,
        9.614753822326662,
        11.495482887268068,
        7.763338874816895,
        5.3677522048950195,
        8.271600547790527,
        11.609968116760253,
        9.428211616516116,
        6.16658535003662,
        6.367434303283691,
        5.544390022277831,
        5.17519556427002,
        3.945321334838866,
        8.583213264465332,
        12.14256775665283,
        12.463270500183107,
        9.371800804138182,
        6.793630699157717,
        6.659521415710451,
        7.703268974304201,
        8.425315773010254,
        5.4611573257446295,
        8.421569465637205,
        9.010211570739745,
        10.446289482116697,
        11.900561378479004,
        11.477096549987792,
        5.591394309997558,
        8.024708778381346,
        12.702276405334473,
        11.56823230743408,
        10.805336738586426,
        8.908076576232908,
        10.126508949279785,
        8.532582221984864,
        63.342271400451665,
        61.796866249084474,
        64.78391495513917,
        63.728985206603994,
        63.394937477111824,
        65.79061563873292,
        68.206737159729,
        67.04908206939697,
        12.921936103820801,
        12.40009215545654,
        9.836052558898928,
        10.437937309265138,
        9.116538307189941,
        9.688289924621582,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        10.926325965881347,
        4.446210311889647,
        3.9302786941528325,
        5.912700294494627,
        7.215742210388186,
        8.85142798614502,
        10.455330375671387,
        5.495985755920408,
        5.024621543884276
      ],
      [
        8.62560718536377,
        12.907197319030761,
        10.190581535339355,
        8.323129066467285,
        9.416095664978027,
        9.049755668640138,
        8.765043418884275,
        8.635948585510256,
        7.756225181579591,
        6.883243675231935,
        9.087032051086425,
        10.199427055358889,
        9.74724913787842,
        7.901758491516112,
        9.207222251892091,
        8.99916202545166,
        8.722988609313965,
        10.433886726379395,
        5.627117424011232,
        6.556902549743654,
        8.953908729553223,
        8.984107063293457,
        6.973140419006349,
        10.220594535827637,
        13.973612159729004,
        9.138833427429198,
        5.154952461242676,
        5.650328163146973,
        11.408816200256346,
        11.897152381896973,
        8.920912361145021,
        9.487542076110842,
        8.731120216369629,
        8.674316108703614,
        5.449642555236817,
        8.89425540924072,
        11.822390769958496,
        11.043919136047364,
        7.242913200378418,
        6.268846000671388,
        8.949873466491699,
        11.81795139312744,
        10.789054481506348,
        8.513695930480957,
        8.714687400817873,
        8.979331398010252,
        10.514982414245605,
        10.491265144348144,
        7.507323097229005,
        4.726182121276857,
        8.495179557800292,
        12.301620780944823,
        9.570970664978027,
        7.949245826721192,
        5.412426231384277,
        8.760168647766115,
        9.105892753601076,
        63.909880348205576,
        62.8147414932251,
        66.4393807144165,
        63.627219886779784,
        60.960233985900885,
        59.097690589904786,
        66.65048062896729,
        64.40292978668212,
        8.928429679870604,
        9.152016349792483,
        10.79597469329834,
        8.210908149719238,
        9.890590278625488,
        9.639032936096193,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.841073265075686,
        7.663553993225097,
        6.757028419494631,
        8.01457691192627,
        7.854966163635254,
        7.706317756652833,
        10.996684379577637,
        7.305045616149904,
        8.416426429748533
      ],
      [
        6.2023371353149415,
        12.660818305969237,
        13.024138862609863,
        11.97012100982666,
        12.90018058013916,
        13.265474678039553,
        11.051253242492677,
        6.556498908996581,
        6.04882315826416,
        5.9385967788696306,
        7.171758598327635,
        8.993742897033691,
        12.117846519470213,
        11.25906502532959,
        9.733288551330567,
        5.783370796203613,
        4.5058059463500975,
        8.443517112731932,
        5.4715986557006815,
        4.7785686416625985,
        9.41981076812744,
        6.84767681121826,
        7.466135536193846,
        11.61840731048584,
        12.971300056457519,
        10.753270301818848,
        5.8852917861938465,
        3.1971054077148438,
        6.308134437561037,
        9.83955750274658,
        10.628432960510253,
        10.234880485534667,
        13.535429023742676,
        10.773830924987791,
        9.388852165222168,
        10.873294136047363,
        11.689073585510254,
        8.369817359924316,
        6.127717033386229,
        6.4860064010620135,
        10.2767465133667,
        13.266706657409667,
        10.487978096008298,
        10.155783393859863,
        11.785012550354004,
        12.07700075531006,
        11.460882438659667,
        10.5001411819458,
        7.2364043197631815,
        7.952969566345214,
        9.7770549697876,
        8.827999183654786,
        5.839573158264159,
        3.8691948165893564,
        4.98604206085205,
        7.812422523498533,
        63.014671607971195,
        58.51813199615478,
        61.9044700088501,
        61.644053016662596,
        63.040894142150876,
        61.85661832427979,
        59.51020932769775,
        63.41478720855713,
        7.408702278137206,
        6.177821601867677,
        6.594564582824706,
        8.164270912170409,
        6.578013908386232,
        6.307675224304198,
        7.163279624938966,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.536628364562986,
        10.820229255676267,
        10.432210227966308,
        10.81533499908447,
        9.556374366760252,
        9.724264152526857,
        7.294921089172362,
        8.49614331817627,
        12.146977851867675
      ],
      [
        9.025234367370608,
        7.920101295471191,
        11.648141532897949,
        11.041691169738769,
        10.863339469909668,
        11.405452613830569,
        12.374516517639158,
        8.090699851989747,
        8.6967435836792,
        6.8440510025024395,
        5.317670921325686,
        6.934390388488769,
        10.731089515686037,
        13.803868202209472,
        11.949862312316895,
        5.9161954116821285,
        4.272847892761231,
        5.858541191101075,
        8.073627265930178,
        8.877934425354006,
        9.628562919616698,
        9.571471702575685,
        7.745470252990721,
        10.202459526062011,
        10.47991609954834,
        9.059860237121583,
        6.057788398742677,
        8.251087654113768,
        6.903407539367677,
        7.615327766418456,
        7.433829917907715,
        10.556914283752441,
        10.545648445129395,
        9.404028968811033,
        10.419151878356935,
        9.187253486633303,
        10.759130439758302,
        10.040983222961426,
        6.387773048400881,
        6.300951667785647,
        8.49678099822998,
        11.748667152404785,
        10.421492683410644,
        9.377597541809081,
        8.42888745880127,
        10.734356040954587,
        11.399281990051271,
        9.461580207824706,
        10.227536918640137,
        11.666461433410646,
        9.675118919372558,
        7.0749202041625985,
        7.69073787689209,
        5.74444310760498,
        6.619892646789552,
        63.51308737945557,
        64.89568399810791,
        62.68446134185791,
        63.20797037506104,
        61.78482804107665,
        63.59209867095947,
        63.50657981109619,
        61.795231117248534,
        63.09798917388916,
        8.217193336486815,
        7.607345130920411,
        4.27410031890869,
        5.737380516052248,
        8.22566857147217,
        6.059105628967284,
        7.673144142150878,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.44301404571533,
        9.553824913024904,
        10.389599052429197,
        9.786678367614748,
        8.781466285705566,
        9.461762290954589,
        5.940741462707521,
        7.640603019714355,
        10.042854057312013
      ],
      [
        10.804319313049316,
        7.788375923156739,
        8.070958198547363,
        8.154632911682128,
        8.180547615051267,
        7.717601768493651,
        6.850488334655761,
        7.456725090026858,
        8.28053037261963,
        10.272841377258302,
        11.418518379211427,
        7.439087165832518,
        6.567761421203613,
        7.822476005554201,
        9.924761619567871,
        10.60859336090088,
        8.891751899719239,
        9.989821708679202,
        13.101618965148926,
        8.201148933410643,
        8.02249842071533,
        9.069399116516113,
        7.43803279876709,
        10.950584877014158,
        10.272297019958494,
        9.354668739318846,
        9.32392394256592,
        10.864830299377441,
        8.846755897521973,
        10.380348426818848,
        10.470126472473144,
        13.052676765441895,
        8.827599830627442,
        7.148904060363769,
        6.750208534240723,
        8.510971809387208,
        10.06002317047119,
        7.93524619293213,
        5.416508018493651,
        5.0785713729858415,
        10.246773262023925,
        13.124956260681152,
        7.399046455383299,
        5.6503665847778315,
        5.018523612976075,
        8.497906715393064,
        6.067261070251465,
        6.350128684997557,
        9.901775016784669,
        12.89239446258545,
        10.201841178894043,
        7.946808479309084,
        11.307405113220213,
        63.890412376403816,
        63.16389963531495,
        63.86011466217042,
        64.57362076568603,
        64.94572733306885,
        64.68095920562745,
        61.05857297515868,
        63.45105991363525,
        63.29880429840088,
        64.76901554107666,
        10.505683235168455,
        10.935214698791505,
        8.630498115539552,
        7.671816795349123,
        10.385612220764159,
        11.975463554382323,
        10.855808982849119,
        9.331612419128419,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        7.836615608215332,
        10.055107398986816,
        10.30901700592041,
        9.768133918762206,
        9.915988365173341,
        12.160561759948731,
        5.9758835067749025,
        2.157698661804199,
        6.131299842834473
      ],
      [
        10.929599220275879,
        8.299961585998535,
        5.89442276763916,
        2.975680732727051,
        4.456161842346191,
        4.558964111328123,
        6.611821784973145,
        10.252997108459475,
        7.607457252502442,
        11.423216438293458,
        12.689770698547363,
        10.74191721343994,
        9.341675148010253,
        7.976122108459471,
        10.180209388732912,
        9.463338050842285,
        12.103286155700683,
        11.774569389343263,
        13.592613014221193,
        9.552180549621582,
        9.504141365051268,
        13.111482048034667,
        13.125114021301272,
        12.979143272399902,
        11.305568153381348,
        8.157405906677248,
        8.59006256866455,
        7.6378244552612315,
        8.567530586242675,
        11.723468147277831,
        10.164924018859864,
        12.232804328918455,
        9.222476722717285,
        8.172316413879393,
        6.988725471496583,
        10.290717369079587,
        11.462803810119631,
        7.85087070465088,
        10.275631187438965,
        11.276625679016114,
        11.49225884246826,
        8.699697257995606,
        6.266411277770995,
        6.231143959045411,
        8.379191505432129,
        8.4122017288208,
        5.420977012634278,
        6.2690103225708,
        7.781544013977051,
        9.608920082092283,
        10.670093299865723,
        64.66934922027588,
        65.20984836578369,
        63.00260190582276,
        60.71673831939697,
        61.80884610748291,
        64.83043363189697,
        68.2594065322876,
        65.8387786026001,
        61.10631296539307,
        60.27153861236573,
        60.95431967926025,
        7.082864784240723,
        8.918529075622558,
        8.087402778625489,
        7.1608637466430665,
        9.679928352355958,
        12.185083351135255,
        12.39020470428467,
        11.175001708984375,
        8.698036842346191,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        7.227958000183104,
        12.244760444641113,
        11.806396919250489,
        10.820004432678225,
        12.24723494720459,
        10.115756141662597,
        6.404703132629393,
        4.045857170104981,
        6.635190025329589
      ],
      [
        9.063213691711425,
        7.553253257751464,
        8.154541740417478,
        8.186589927673339,
        8.697595161437988,
        8.871528358459472,
        7.498186012268064,
        9.549082511901858,
        7.285965400695801,
        6.810843284606932,
        8.620656089782713,
        9.123698890686036,
        9.94909401702881,
        9.00256273651123,
        8.842667091369627,
        9.08465732574463,
        9.907953605651855,
        8.628088722229002,
        10.25532920074463,
        7.499021553039551,
        8.539417304992675,
        9.634280281066893,
        8.862329597473142,
        7.83234073638916,
        9.350767860412596,
        8.514987220764162,
        6.148097389221192,
        7.296482536315917,
        10.0945950088501,
        10.483311225891114,
        8.786542701721192,
        11.539007347106931,
        10.106685722351074,
        6.587773796081542,
        4.455475067138671,
        8.856187019348145,
        10.29981909942627,
        8.441352409362793,
        10.645228645324707,
        10.486711402893064,
        60.81527225494385,
        63.07818424224854,
        63.58418430328369,
        62.896495521545404,
        63.290853981018074,
        61.805276222229,
        59.38908676910401,
        64.09314780426025,
        64.5043219985962,
        62.79613486480713,
        63.590047340393056,
        65.65860776519776,
        64.3695168838501,
        61.31886197662354,
        61.23745035552979,
        63.22112766265869,
        66.47220096588134,
        66.76480252838135,
        62.184557106018076,
        62.02219669342041,
        63.53498355865479,
        63.960367042541506,
        8.624050926208497,
        8.39774050140381,
        8.267213325500489,
        8.803670234680176,
        9.291165458679199,
        9.00290473175049,
        10.360697593688965,
        10.662974800109865,
        9.082300758361818,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        7.3217778091430645,
        10.67814005279541,
        12.079234779357911,
        9.609973793029788,
        11.662790702819827,
        9.649911613464354,
        6.626480812072753,
        4.123724647521975,
        8.761057624816893
      ],
      [
        9.896853553771972,
        11.018943305969238,
        12.705287940979005,
        9.044000160217287,
        11.093951545715331,
        13.776051460266114,
        9.85650005340576,
        9.356731895446778,
        7.866214210510254,
        4.351142395019529,
        5.556768806457519,
        6.258817085266113,
        9.48767308807373,
        11.19638256072998,
        11.043821662902833,
        9.327763221740724,
        6.689780158996584,
        6.476321998596191,
        4.790101234436037,
        6.786570823669432,
        9.18456812286377,
        6.8673120040893565,
        5.637988914489746,
        8.709241142272951,
        8.357816871643067,
        6.790600059509277,
        9.092383522033693,
        7.983368522644042,
        10.924425910949708,
        9.704153312683104,
        8.985490699768064,
        11.706085121154786,
        12.93746334075928,
        9.129785041809082,
        4.467471168518067,
        5.29140467071533,
        8.238914894104006,
        9.016237419128416,
        9.18365375518799,
        6.251900520324707,
        32.28252681732178,
        63.317136024475104,
        64.54940897369384,
        65.01162711334229,
        65.83474729156494,
        63.77095200347901,
        61.86910733795166,
        66.63936740875243,
        65.56622415924072,
        61.50151358795166,
        59.04299124908448,
        63.01298764801025,
        65.17719956207276,
        61.06048954010009,
        61.945283180236814,
        64.67358921051026,
        65.1991423110962,
        62.877263465881356,
        59.32534519195556,
        61.353052406311036,
        42.96073867034912,
        7.776656181335447,
        8.089752449035643,
        10.2186976852417,
        10.002517890930175,
        9.30313878631592,
        8.777143455505371,
        7.780300788879394,
        8.739029075622561,
        8.027497596740723,
        9.94861072540283,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        7.335419486999513,
        7.752707191467287,
        7.231685920715332,
        5.642248069763184,
        9.691594261169435,
        8.975280372619629,
        8.561768455505373,
        6.403841453552246,
        9.144137229919433
      ],
      [
        11.365343345642088,
        14.511875984191896,
        11.792295661926268,
        6.876646308898927,
        9.342391883850098,
        11.18008945465088,
        9.856265159606934,
        6.490764732360841,
        6.656411323547363,
        6.605990333557131,
        4.168053764343263,
        4.165457466125488,
        6.28755750274658,
        11.631166755676269,
        12.094921913146973,
        7.431252632141113,
        7.811845115661619,
        6.060504508972169,
        4.349545082092284,
        4.485898536682129,
        9.366602577209473,
        9.30313907623291,
        7.490292030334473,
        10.340754325866698,
        13.028314216613769,
        8.92246086883545,
        7.716301734924315,
        10.683442832946778,
        8.91722974395752,
        9.628061012268066,
        10.761303993225098,
        9.943335899353027,
        11.77624570465088,
        10.070774452209474,
        8.782142784118655,
        9.307057106018064,
        9.218573799133303,
        9.95059192657471,
        7.617269645690918,
        5.491903938293458,
        9.15588550567627,
        7.717634956359863,
        8.983374214172365,
        65.71947025299072,
        69.26138913726807,
        63.35244040679932,
        64.45485330963135,
        65.76521341705322,
        66.1756555557251,
        60.87605271148681,
        60.22736215972901,
        64.02128672027588,
        65.11981925201415,
        62.16168332672119,
        61.36369736480714,
        61.962199378967284,
        61.08239066314698,
        61.21200839996338,
        61.449955787658695,
        6.432579261779786,
        6.584387901306151,
        6.155504295349122,
        8.345448265075682,
        11.717035682678222,
        7.9194794998168945,
        9.391881477355959,
        10.029449043273928,
        10.582706398010252,
        7.484704078674316,
        5.206587623596192,
        9.034765357971189,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        6.779653511047364,
        6.233165473937987,
        7.126581352233885,
        9.407352485656737,
        12.883020606994627,
        11.408128318786623,
        7.553422248840331,
        5.5143140487670905,
        7.223822334289551
      ],
      [
        5.783684547424315,
        10.16638907623291,
        9.785851905822753,
        5.165309410095215,
        6.571928123474123,
        6.131905769348144,
        6.520655174255371,
        9.560361457824705,
        13.507193717956543,
        12.741864906311037,
        10.646007423400881,
        7.946834297180175,
        5.636075736999512,
        12.842259925842285,
        13.234593376159665,
        8.511975456237792,
        5.0325246658325185,
        9.346047065734865,
        10.404877281188966,
        8.564093238830566,
        7.53595774078369,
        9.062087226867675,
        9.593544593811036,
        11.863812873840331,
        10.973025672912598,
        8.529726173400881,
        10.371624397277834,
        8.779311088562011,
        6.0253588027954095,
        7.835687263488769,
        6.614553825378419,
        6.051982582092286,
        7.368700920104981,
        7.014018104553223,
        8.309470542907714,
        6.766662010192871,
        10.059295845031738,
        9.253986274719239,
        6.052095207214357,
        4.490347923278808,
        6.917480506896972,
        9.9901294631958,
        9.56317977142334,
        10.495243110656737,
        10.259980995178225,
        8.580106803894044,
        7.057448982238768,
        11.184171012878416,
        8.815206565856933,
        7.6646499557495105,
        10.587415229797365,
        12.259574516296386,
        11.219541450500486,
        8.592849250793456,
        6.725195716857911,
        9.847904319763181,
        10.083879402160644,
        11.251067253112794,
        11.760308906555174,
        14.632566780090333,
        13.096854286193846,
        7.054668144226074,
        4.467911308288574,
        9.933390937805175,
        12.202837745666503,
        9.383889503479004,
        10.374671424865724,
        10.029268211364744,
        7.106967613220213,
        7.145861091613771,
        8.371578315734865,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        11.509979286193847,
        10.271823905944824,
        9.371423942565919,
        6.7078487472534185,
        12.226288459777834,
        10.583466133117675,
        9.521967613220212,
        8.159537422180176,
        9.511489097595216
      ],
      [
        4.50729249572754,
        9.99731014251709,
        11.080528602600097,
        9.463280799865721,
        7.6317102737426765,
        4.9330304489135735,
        5.079497550964355,
        8.263192222595215,
        12.413191658020018,
        16.149918251037597,
        11.472665748596192,
        9.072519020080566,
        7.224183753967285,
        11.289777656555174,
        9.674178260803224,
        9.978376380920409,
        8.888401222229003,
        8.606985282897949,
        7.089380561828612,
        4.61138889312744,
        3.3936556167602543,
        8.05169828033447,
        8.720097221374512,
        10.56205847930908,
        9.492189796447754,
        9.607435295104981,
        8.958649131774902,
        5.644461311340333,
        7.814362648010253,
        9.129513465881345,
        8.324829048156737,
        8.754182655334475,
        6.364067726135254,
        3.038714218139649,
        4.643753868103026,
        9.061274421691895,
        12.162472999572756,
        13.163143501281738,
        8.975245979309083,
        5.528095542907714,
        8.87883826446533,
        11.98277320098877,
        12.859216835021975,
        11.49075107574463,
        10.134659034729005,
        7.6191441116333,
        6.372237678527831,
        3.28575779724121,
        5.702012321472168,
        7.831207008361815,
        9.037454933166504,
        12.998190376281737,
        12.067405754089357,
        9.367516624450683,
        9.165254264831542,
        8.738874931335449,
        8.627100105285642,
        12.006372993469238,
        13.407605110168458,
        15.687087715148927,
        11.962721168518065,
        9.432354164123534,
        3.8366274185180664,
        9.249885414123533,
        8.422255149841309,
        11.197423759460449,
        11.02580088043213,
        8.476471839904786,
        8.972427284240723,
        8.772261924743653,
        9.887595863342284,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.362369575500487,
        7.106951774597167,
        7.191993141174315,
        7.471073631286622,
        9.331870307922365,
        7.346307914733885,
        10.518563026428225,
        7.352186805725097,
        11.739523384094237
      ],
      [
        8.687499504089356,
        10.57496479034424,
        8.442104637145995,
        10.224388221740725,
        9.551049140930175,
        8.891402015686033,
        6.138462028503419,
        6.184589012145995,
        12.653086799621583,
        13.590452964782713,
        9.624604530334473,
        7.981343193054201,
        8.405045890808104,
        6.880377769470215,
        6.915112922668456,
        9.328599891662599,
        7.376437019348145,
        9.527787483215334,
        8.08230071258545,
        9.825999351501466,
        9.012694511413574,
        11.642377174377444,
        8.654324729919434,
        8.67390552520752,
        5.810908699035643,
        8.60808094024658,
        10.83488645172119,
        8.775677574157715,
        9.589269050598144,
        10.7947982711792,
        8.894032554626463,
        9.621031806945801,
        9.176860832214356,
        7.247076820373536,
        4.2327381668090815,
        8.83154140472412,
        13.159845451354983,
        11.334243644714356,
        8.22673281097412,
        6.809226860046387,
        8.786053657531738,
        10.036010368347167,
        12.224009086608888,
        8.077501457214353,
        7.4028471603393555,
        8.8950239944458,
        7.1677849349975595,
        6.76875286102295,
        10.72396212005615,
        9.22204746246338,
        8.85371601104736,
        9.423087196350096,
        9.979338203430174,
        7.752668846130369,
        8.097331169128417,
        8.204745750427247,
        8.188065773010251,
        7.24364853668213,
        10.587382453918458,
        13.565882316589356,
        9.157897605895997,
        5.82900640106201,
        6.851975868225097,
        9.843532386779785,
        8.416454872131347,
        8.827994270324709,
        10.478941963195801,
        10.085716346740725,
        10.066742469787599,
        9.081940040588378,
        9.073612556457519,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.462676933288574,
        7.740855293273924,
        9.290691642761232,
        8.984071769714355,
        9.512638542175292,
        8.415262123107908,
        8.641709526062012,
        6.310186408996582,
        8.702826652526856
      ],
      [
        9.686136955261233,
        10.113837532043455,
        8.492588523864747,
        9.338732246398926,
        9.33511186981201,
        10.15196363067627,
        10.286342811584472,
        8.12515352630615,
        14.475616081237792,
        14.856919227600098,
        11.090513801574708,
        6.447065025329589,
        5.858204765319826,
        4.3805361709594735,
        7.382162223815918,
        9.152031929016111,
        9.558796287536623,
        9.2251362991333,
        12.079276802062989,
        13.220224342346192,
        13.079247718811033,
        14.372673866271974,
        7.875799888610839,
        6.727900978088378,
        5.701935859680177,
        8.548505653381348,
        9.752552650451662,
        7.39108713531494,
        8.154232261657715,
        8.138486061096192,
        6.497212806701661,
        8.200057167053224,
        11.659445350646973,
        8.296536842346192,
        5.521666511535646,
        9.467724617004393,
        11.615356803894045,
        8.91873299407959,
        6.840999275207519,
        9.385849388122558,
        8.957864280700683,
        12.522035316467285,
        9.937907814025877,
        10.5719486618042,
        7.884779228210448,
        6.196873451232911,
        3.924089637756346,
        7.442274879455567,
        10.267163581848145,
        11.581976844787597,
        11.127877464294436,
        7.7704741897583,
        7.612183738708495,
        7.380161323547362,
        5.902261390686036,
        8.028416511535646,
        5.893741737365723,
        4.224753700256347,
        7.6777123184204115,
        7.469037422180175,
        5.347259330749512,
        6.575355659484863,
        9.954677131652833,
        10.5991060256958,
        10.918647987365723,
        9.583406867980955,
        13.176254631042482,
        10.207457252502444,
        9.740059700012207,
        6.881592643737793,
        7.72397388458252,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        11.086767402648924,
        9.438014533996583,
        9.596074409484864,
        11.532814949035647,
        13.543459083557131,
        12.206293815612796,
        11.498067573547363,
        8.007611366271973,
        9.64754906463623
      ],
      [
        8.45860262298584,
        10.249563117980955,
        10.55792050933838,
        12.108793006896974,
        8.1683042678833,
        9.336281684875487,
        11.465044502258301,
        8.365958992004394,
        10.945339149475096,
        13.517755165100098,
        11.376808174133302,
        6.326034996032714,
        5.303935142517091,
        6.301687171936035,
        8.1579448928833,
        10.420728874206542,
        9.947846412658691,
        8.191717292785647,
        10.921791481018069,
        10.591521308898926,
        12.689979042053224,
        14.280484580993651,
        9.481167915344237,
        9.189385704040525,
        7.479762489318848,
        7.369936653137209,
        7.544523109436035,
        8.322928153991697,
        8.370795845031736,
        7.704120872497558,
        8.072214515686035,
        7.676407539367677,
        9.018850013732909,
        7.095234474182128,
        5.6695520858764645,
        10.565179985046385,
        12.961223640441894,
        9.40817584991455,
        8.522388526916505,
        10.16264636993408,
        9.731457313537597,
        9.693314064025877,
        10.818986320495604,
        9.095935569763185,
        6.849618247985838,
        6.233382209777833,
        7.876526405334474,
        10.493900123596191,
        12.164320610046389,
        14.996277214050295,
        9.315366340637205,
        5.046541603088379,
        8.251380134582519,
        12.026356178283692,
        9.2128374710083,
        5.765800178527833,
        6.509259666442873,
        5.679310249328612,
        7.83636566925049,
        7.036858558654785,
        7.728366447448732,
        10.347883628845217,
        10.003510795593261,
        10.0439829788208,
        13.268736061096192,
        10.101974006652831,
        9.669496864318848,
        9.583206184387208,
        8.79272174835205,
        4.929246116638183,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.227530296325682,
        10.959410102844238,
        6.2277865066528335,
        6.76090372467041,
        11.941562355041505,
        10.226040672302247,
        9.183700355529787,
        10.528065589904784,
        9.74495726776123,
        9.611797630310058
      ],
      [
        10.684091911315917,
        8.988302909851072,
        6.462476905822754,
        7.081242973327637,
        6.16305506134033,
        9.13314284515381,
        9.061990028381345,
        7.943526420593262,
        9.460943824768066,
        10.030856529235841,
        6.137946098327639,
        6.826775596618653,
        11.038498237609865,
        10.724041511535646,
        8.556129371643067,
        7.773638557434083,
        11.656121711730957,
        7.7357142105102525,
        8.37273218536377,
        8.092427360534668,
        6.662751029968263,
        7.193804069519043,
        8.290123527526855,
        9.436350227355959,
        9.516849647521973,
        11.490993659973142,
        10.57396228790283,
        8.028853691101077,
        7.592862922668459,
        9.657582862854003,
        10.870438819885251,
        9.161093788146971,
        10.731756385803223,
        7.8436954574584945,
        5.2061930313110345,
        7.563940757751464,
        7.746344398498536,
        7.185626670837401,
        9.465809074401854,
        10.925936485290528,
        8.198997718811036,
        8.761438011169432,
        10.230379997253419,
        6.927189033508299,
        8.953832313537596,
        9.390577857971191,
        6.04536775970459,
        6.083254524230959,
        9.426936973571777,
        10.751567100524902,
        8.19265782928467,
        5.569072517395021,
        6.751280387878417,
        9.717033912658692,
        10.059647789001467,
        12.19691622161865,
        11.346711021423339,
        9.653300529479978,
        10.8015745010376,
        9.446705711364746,
        9.791295524597167,
        11.406664054870603,
        9.650708869934082,
        8.918095222473145,
        9.96960736846924,
        9.814883094787596,
        6.365297401428222,
        6.467898262023926,
        7.501755027770997,
        7.373109466552734,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.700681297302246,
        11.498372779846193,
        6.2973349533081056,
        6.851818077087401,
        7.640952095031739,
        9.60396949005127,
        9.34600365447998,
        10.683446052551268,
        11.33046297454834,
        8.230440284729006
      ],
      [
        7.446949195861816,
        8.004825599670411,
        10.655285682678223,
        5.549027343749998,
        3.056113067626953,
        6.4662816696166985,
        10.71998996734619,
        8.750672615051272,
        10.575299476623535,
        8.132856880187987,
        8.43515026092529,
        7.549548164367675,
        11.474718025207519,
        12.370621971130369,
        12.552815742492676,
        10.03255066680908,
        8.565005561828613,
        7.526273551940918,
        11.788515800476077,
        9.08121672821045,
        9.550504798889158,
        10.703174919128418,
        8.530882087707518,
        7.793251518249512,
        10.892826881408691,
        12.10091675567627,
        9.685318153381345,
        8.201987571716309,
        9.35253046417236,
        10.914941093444824,
        11.223514533996582,
        11.301246376037597,
        10.018117393493654,
        5.075288230895996,
        4.262710319519044,
        5.869725761413573,
        7.1216671066284185,
        5.8910244522094715,
        8.68976358795166,
        6.863619956970215,
        8.010878181457521,
        11.19327335357666,
        9.539759162902833,
        7.124206901550295,
        9.789208503723145,
        8.67286009979248,
        5.289615165710451,
        3.0267514419555663,
        8.413343101501464,
        8.903570503234864,
        5.489916206359865,
        6.52285733795166,
        8.899049049377439,
        10.191876197814942,
        9.879110511779785,
        12.175366218566893,
        10.430072761535644,
        9.579701972961423,
        12.808897377014162,
        15.077946372985842,
        12.875822975158691,
        9.665986366271973,
        9.540858512878415,
        12.152790748596189,
        11.93632511138916,
        8.27577352142334,
        6.876870185852049,
        7.95625936126709,
        7.307885932922364,
        7.5634723739624015,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.688899803161622,
        11.232484992980957,
        8.635698707580566,
        9.825829139709473,
        11.283602943420412,
        9.746348274230957,
        9.972115882873535,
        7.45467046356201,
        7.667301231384279,
        10.135023002624514
      ],
      [
        8.97147617340088,
        10.663897941589354,
        10.712844108581542,
        6.324332939147951,
        6.295254783630369,
        9.501331596374513,
        12.283730201721191,
        10.92413988494873,
        6.647662528991699,
        5.098866554260255,
        8.759198417663576,
        10.675302787780762,
        9.124282234191895,
        8.658233558654786,
        11.168155525207517,
        8.875800895690919,
        5.415115089416503,
        7.015611595153807,
        10.45942784881592,
        9.359216835021975,
        9.128885993957518,
        10.632490821838381,
        8.934290534973144,
        10.035682136535645,
        8.847505805969238,
        7.8865735244750965,
        7.392830207824709,
        9.113498695373536,
        8.250705589294434,
        11.360032035827636,
        9.140439338684082,
        9.3241548538208,
        10.688426612854002,
        7.018414451599121,
        6.959541511535644,
        8.325662498474124,
        7.994902595520021,
        7.005202476501466,
        9.677016685485839,
        7.144753150939941,
        8.172881660461428,
        11.626016639709473,
        10.687568840026856,
        8.338068000793456,
        10.612058921813965,
        8.65522891998291,
        5.564104194641114,
        6.899689384460451,
        9.333353523254395,
        9.412339515686035,
        9.27317569732666,
        11.61677564239502,
        10.237787513732911,
        6.564706184387205,
        9.490144248962402,
        8.986712226867674,
        6.283637306213379,
        5.742278388977049,
        10.894047813415526,
        11.769054359436034,
        9.040918083190917,
        6.200970726013182,
        7.1257512893676775,
        9.933582572937013,
        12.007684333801269,
        9.006888313293459,
        8.089017448425295,
        9.03787816619873,
        6.38276148223877,
        7.877710075378417,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.095556602478027,
        10.38927582550049,
        7.288889961242674,
        9.764227943420408,
        9.060175437927246,
        9.36309238433838,
        8.176043144226075,
        7.648577461242674,
        5.950864921569824,
        8.768613929748533
      ],
      [
        10.194156867980958,
        10.75308863067627,
        11.844490867614745,
        7.012069007873535,
        8.212479835510251,
        12.428829643249511,
        11.500494956970215,
        11.729736579895018,
        7.1349816970825195,
        6.02278342437744,
        6.702074516296385,
        8.44331760406494,
        7.145010643005371,
        8.566133491516112,
        7.564533363342285,
        8.798043128967286,
        6.111035697937012,
        4.967692237854003,
        6.596303627014159,
        10.195501686096193,
        9.910506507873535,
        7.321709358215333,
        8.742747642517088,
        11.227654670715332,
        6.407852561950683,
        4.6971275100708,
        8.038369270324708,
        11.184551002502442,
        10.578833244323732,
        9.002421577453614,
        8.544019264221191,
        8.972368995666503,
        9.455907157897947,
        9.45475008392334,
        8.347532600402833,
        9.22921912384033,
        7.006286476135255,
        8.781736335754395,
        10.363891609191896,
        9.29546294403076,
        7.51389069366455,
        10.001259269714353,
        8.332750495910645,
        8.143765022277833,
        8.798796516418456,
        10.442961601257323,
        10.117607551574707,
        11.416518867492677,
        11.546652595520019,
        9.55907727813721,
        12.014785957336425,
        11.459245841979978,
        6.3209431533813465,
        5.069389167785644,
        4.227847908020021,
        6.383392921447754,
        5.545941566467285,
        6.62280783843994,
        6.476142921447753,
        6.629229652404785,
        4.271237663269041,
        5.2623652267456045,
        7.762950736999514,
        11.398470985412597,
        8.518185508728028,
        7.508625038146974,
        7.120375358581544,
        6.335238273620604,
        4.951228981018065,
        8.640455696105956,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.605122581481936,
        7.936765785217286,
        5.196213829040527,
        8.139184715270996,
        6.235866493225096,
        5.976006507873535,
        7.624184059143065,
        10.194355339050293,
        7.1046745681762715,
        5.06138207244873
      ],
      [
        10.004012062072754,
        8.604614662170412,
        9.06944438934326,
        6.014867332458497,
        8.75728443145752,
        9.272618659973144,
        10.713639717102051,
        10.584821220397949,
        8.524211402893066,
        8.562614128112791,
        8.71826015472412,
        8.786256248474121,
        7.610208274841309,
        7.142172492980958,
        4.512520591735839,
        8.934549415588378,
        10.496456520080567,
        6.842724174499512,
        8.696700904846189,
        10.703448402404785,
        9.350068489074708,
        9.961691413879393,
        11.491188850402832,
        9.569933662414549,
        5.772440391540528,
        6.030662696838377,
        10.491832862854004,
        10.075325813293457,
        9.96810620880127,
        8.325349128723147,
        8.173468345642092,
        7.892150001525877,
        7.150456550598143,
        8.479298957824707,
        10.20092797088623,
        8.852519218444826,
        5.890231056213381,
        7.167896278381349,
        9.207882682800292,
        11.096459526062013,
        8.598716606140137,
        10.18896132659912,
        8.965670890808106,
        7.289011360168459,
        6.035559608459472,
        9.215491188049317,
        11.64871006011963,
        9.763002082824705,
        6.520933418273927,
        6.537870468139648,
        10.127150230407715,
        9.344534324645998,
        7.51248085784912,
        6.00306436920166,
        6.766578361511229,
        7.822710487365722,
        10.869822639465333,
        9.911904640197754,
        5.284654060363771,
        5.6369051742553715,
        5.740295097351073,
        7.868804908752441,
        9.641663169860841,
        11.266202278137207,
        6.7257942123413095,
        8.881142555236817,
        8.770478248596191,
        10.462303749084473,
        7.969457328796388,
        8.086201240539552,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        7.2358307723999005,
        8.232802421569822,
        10.542596458435057,
        11.03597058868408,
        7.3649054336547835,
        6.341244560241698,
        6.637444450378418,
        10.886446083068847,
        9.24734934234619,
        5.459188621520994
      ],
      [
        7.393463340759276,
        9.81342435455322,
        10.176775245666505,
        8.100982215881348,
        7.230750892639161,
        7.201608802795409,
        6.5014059829711925,
        5.745545005798341,
        7.849329612731935,
        9.925971153259276,
        9.11993109893799,
        10.978511329650878,
        9.94383132171631,
        7.875664710998535,
        4.2753801193237315,
        9.361005836486818,
        10.123369087219238,
        8.76171410369873,
        12.893804954528811,
        12.00657834625244,
        6.563197547912598,
        5.355754905700685,
        7.880738288879392,
        8.58994541168213,
        9.021555580139161,
        11.682181983947753,
        14.178521278381346,
        9.96099715423584,
        7.760293632507324,
        6.951834373474121,
        9.784739616394042,
        10.397904090881347,
        9.363989143371583,
        9.696083381652834,
        11.058883003234861,
        9.890582511901854,
        7.473156837463378,
        8.367468955993651,
        8.613051445007322,
        10.5753450088501,
        8.899607337951661,
        10.637168144226074,
        10.234116493225098,
        6.513492477416992,
        3.1512862854003902,
        7.542346977233887,
        14.077740806579591,
        12.902898994445799,
        7.156325752258301,
        5.009466377258299,
        7.132651206970216,
        9.380348335266113,
        9.181596214294434,
        8.768744285583495,
        8.506141670227052,
        7.202995292663573,
        12.48069245147705,
        12.391856620788573,
        8.476393348693847,
        9.610221076965331,
        13.020659309387206,
        11.069023506164552,
        7.539453712463377,
        7.999886543273924,
        8.200921775817871,
        9.494411399841308,
        10.349643928527833,
        10.640806831359864,
        9.845619163513184,
        10.090660621643067,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.401696235656736,
        7.722441566467285,
        11.066587333679202,
        12.65157791900635,
        9.83790651702881,
        5.131992759704591,
        5.910463096618653,
        9.781473960876465,
        13.991454780578614,
        12.03010991668701
      ],
      [
        9.031653236389161,
        10.637593559265135,
        7.597224739074708,
        8.332341590881349,
        4.958750846862792,
        5.982075096130373,
        7.213881919860839,
        7.161829536437988,
        6.6857442398071285,
        6.478695655822754,
        7.674549110412599,
        10.498605003356936,
        13.877580604553224,
        8.5426448135376,
        5.309874519348146,
        8.387207084655763,
        10.718339393615722,
        10.692042137145997,
        13.569095848083496,
        9.868351570129395,
        7.100354270935057,
        5.375522438049316,
        5.614449729919436,
        10.011485969543457,
        11.703227149963379,
        13.62257022857666,
        13.495402030944824,
        11.558648414611817,
        7.992701301574705,
        8.903023033142091,
        9.590653953552248,
        9.129324867248535,
        8.274580635070802,
        9.86968029022217,
        12.60928914642334,
        12.05789025115967,
        9.33974094390869,
        8.236416419982909,
        6.390328941345214,
        5.018415443420409,
        7.8683104324340825,
        7.459454032897948,
        7.847035163879394,
        6.432370414733889,
        6.09121282196045,
        8.616157569885253,
        11.870809410095212,
        13.317740547180176,
        9.644994606018066,
        8.38824016571045,
        7.427290504455566,
        7.149666191101076,
        6.485822837829588,
        8.804868598937986,
        7.925755561828613,
        7.824551200866701,
        12.112887245178221,
        12.629873573303222,
        12.281164131164552,
        13.660627815246581,
        12.429979301452637,
        9.31141625213623,
        9.28070972442627,
        11.065325660705568,
        11.449146003723143,
        12.371878273010253,
        10.139995994567869,
        8.346333671569823,
        12.461556465148924,
        10.307262336730957,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.592177986145018,
        8.201858421325682,
        9.242075019836427,
        10.099844184875487,
        7.933899528503417,
        7.058652381896973,
        8.050909049987794,
        9.660814888000488,
        14.383517173767089,
        12.895473472595214
      ],
      [
        8.567682380676267,
        12.128111808776858,
        9.84020760345459,
        9.753241218566895,
        7.898078178405761,
        8.77902141571045,
        10.37894835662842,
        9.783357597351074,
        7.45582167816162,
        4.55866509246826,
        9.105871086120608,
        11.381113365173341,
        12.447550071716307,
        5.956926750183104,
        6.349399284362793,
        8.363377189636232,
        9.01338549041748,
        8.208489662170408,
        9.63974713897705,
        11.314438789367678,
        8.711615867614746,
        4.736858726501467,
        7.949460258483889,
        10.111183357238769,
        8.316662330627441,
        8.790084266662596,
        9.750938148498534,
        9.379224632263181,
        9.106579505920408,
        9.51453714752197,
        8.436992378234862,
        6.7842290878295906,
        9.419644477844237,
        9.838682640075682,
        10.971593849182128,
        8.50544780731201,
        9.737042655944826,
        8.87829598236084,
        7.455321037292482,
        7.124840774536132,
        9.165594520568845,
        7.596290199279785,
        7.300068260192873,
        9.772047813415526,
        9.011970176696778,
        8.787466468811033,
        11.879757682800292,
        11.552322959899904,
        9.846448829650878,
        10.003854591369631,
        9.219557380676271,
        5.3489769821166995,
        6.0372356338500985,
        10.581484748840332,
        10.157309501647951,
        9.188365821838381,
        10.630447151184082,
        9.874547996520995,
        9.027715141296387,
        8.933381355285647,
        8.732329292297365,
        6.251332038879394,
        6.732539466857908,
        12.078411170959473,
        12.103739692687988,
        9.130698127746584,
        5.966207191467284,
        5.623885246276856,
        9.939882270812987,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.687141532897947,
        11.457832740783694,
        10.076196403503417,
        10.022704353332522,
        10.124086860656739,
        8.46212574005127,
        7.971375007629394,
        9.010318214416504,
        6.502759391784668,
        8.06153244781494,
        9.435574913024901
      ],
      [
        11.538372459411619,
        12.187650352478027,
        11.145235328674318,
        13.038035148620608,
        9.307001136779785,
        8.840628807067873,
        9.361635368347166,
        8.666050483703614,
        5.350681602478026,
        7.197458045959472,
        12.050919090270995,
        12.010995185852053,
        10.427965110778807,
        6.661016807556152,
        6.849875617980956,
        6.44465488433838,
        5.970193641662597,
        5.069932609558105,
        7.659399909973146,
        8.742253791809084,
        10.904500541687014,
        7.994182151794433,
        6.164563728332521,
        5.763779945373535,
        4.207868515014649,
        4.603812950134277,
        6.823731376647949,
        8.233445030212401,
        10.98390547943115,
        7.449756111145021,
        6.540228965759276,
        7.792456398010252,
        12.650672706604006,
        12.72750818634033,
        7.8927133255004875,
        5.660624656677246,
        6.843379112243653,
        7.941025596618651,
        10.631870368957522,
        10.649823768615722,
        12.260407997131345,
        10.887091682434082,
        10.477275901794435,
        10.098135444641112,
        11.184835563659668,
        9.129108909606934,
        8.851630668640137,
        8.148504875183107,
        7.885369880676269,
        11.883100273132325,
        9.921052467346193,
        8.650530952453614,
        9.855070854187012,
        14.291641441345213,
        12.662454490661624,
        13.051635368347167,
        12.670479637145995,
        9.567888984680174,
        4.511540794372557,
        5.285277305603028,
        4.482742301940917,
        3.407042076110841,
        5.356292457580565,
        9.351370048522949,
        9.578660591125487,
        4.8929421463012694,
        3.4906782455444336,
        5.953967414855956,
        9.426824836730958,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        7.713680534362794,
        9.048303504943846,
        9.069528831481932,
        13.236300422668457,
        11.917313636779785,
        10.004708137512207,
        8.861629920959473,
        6.756035026550293,
        4.428475669860838,
        5.63762328338623,
        6.107190910339355
      ],
      [
        10.996065254211423,
        7.449291526794433,
        8.32642638397217,
        11.422278678894045,
        9.493733604431153,
        8.726936439514162,
        6.345041236877442,
        6.382697868347169,
        5.50316872406006,
        9.905032051086426,
        10.946769599914553,
        11.489756324768067,
        10.582468421936035,
        8.755220985412599,
        9.626996894836424,
        7.92215326690674,
        8.16144750213623,
        6.959459800720214,
        6.682231925964356,
        7.014494117736817,
        10.531775459289548,
        10.871788581848143,
        8.196455696105957,
        5.087021125793456,
        5.801983726501465,
        7.6569595108032225,
        10.061428565979003,
        8.911728477478029,
        9.61472275543213,
        7.691606422424314,
        6.091920570373535,
        10.624739936828611,
        11.967303230285644,
        11.41982122039795,
        7.200591789245607,
        7.724885108947753,
        6.276967613220215,
        8.663908302307128,
        10.161803596496583,
        12.23157462310791,
        10.720590980529785,
        10.099098426818848,
        12.01276178741455,
        8.662750404357908,
        8.95973366546631,
        10.117881111145017,
        9.2293998336792,
        6.960743476867677,
        8.598767478942872,
        12.417551536560058,
        10.863578819274903,
        7.68253830718994,
        9.1508265914917,
        11.036143135070802,
        10.301521171569824,
        11.490574363708497,
        13.303085258483886,
        9.186735527038575,
        6.797232246398927,
        6.812613990783692,
        5.376389396667481,
        5.972983146667481,
        6.348338981628416,
        6.985116371154785,
        8.765343940734866,
        4.642746025085451,
        4.384008872985838,
        4.940199546813965,
        9.517155662536624,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.933288887023927,
        8.154323554992676,
        8.869665702819823,
        11.919599189758301,
        11.733578895568847,
        10.268404670715334,
        8.175563056945801,
        9.551946296691895,
        7.615508064270021,
        8.131385093688962,
        6.752485771179199
      ],
      [
        6.923190254211427,
        4.367805854797364,
        5.821739830017091,
        9.587524559020999,
        9.578390068054198,
        8.832810279846193,
        11.120011970520018,
        13.02447057342529,
        10.576993186950684,
        7.9502835464477535,
        8.175451011657714,
        9.159720771789551,
        6.106582603454591,
        7.1540082321166985,
        10.715342948913573,
        10.274576560974122,
        11.085203895568846,
        9.767444953918456,
        9.57481241607666,
        6.318497138977051,
        8.009551155090332,
        12.342697120666504,
        11.640541999816897,
        8.938791633605959,
        8.349056800842284,
        10.307375053405764,
        9.815769691467285,
        8.678896797180176,
        9.135548805236816,
        7.020540641784667,
        9.733595024108887,
        9.71921509552002,
        6.842298332214355,
        6.421374305725099,
        8.740939689636228,
        10.157719718933105,
        11.160358207702636,
        9.019234352111816,
        10.913610176086426,
        11.73989867401123,
        8.027934150695799,
        7.085317848205566,
        8.580439659118653,
        6.788230949401857,
        8.803061103820802,
        8.834974235534666,
        6.263985496520995,
        8.350937553405764,
        10.66031273651123,
        11.280722206115723,
        8.47544753265381,
        5.39365811920166,
        5.77719033050537,
        7.338633186340331,
        7.201083610534667,
        8.055266410827635,
        9.784477424621581,
        10.255758491516112,
        7.698350502014161,
        9.11003359222412,
        10.953295295715332,
        11.628432670593263,
        9.025703880310058,
        8.174118858337401,
        11.089817024230957,
        11.391812553405764,
        8.60097663116455,
        7.96411235809326,
        9.586410408020022,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.739806480407715,
        10.479612434387207,
        9.240167533874512,
        11.121966438293455,
        12.94347925567627,
        9.803584144592286,
        6.906301719665528,
        10.50056990814209,
        8.538895668029785,
        9.722598442077636,
        10.72021984100342
      ],
      [
        7.0533681716918935,
        5.413958000183104,
        3.4220829315185544,
        7.048557243347169,
        7.249704948425293,
        8.062580451965331,
        8.90841118621826,
        11.506351280212403,
        9.480319252014162,
        9.759252906799318,
        8.842853752136229,
        6.849735710144042,
        3.7801148605346686,
        6.568096473693849,
        9.194001914978028,
        13.420185066223144,
        13.233118843078614,
        11.002901985168457,
        10.72588488006592,
        9.206038139343264,
        8.46281011199951,
        13.076667457580566,
        13.926586082458495,
        12.036130363464355,
        9.898942436218263,
        13.148800239562988,
        12.731857246398924,
        9.05015845489502,
        6.184400413513185,
        5.972237770080568,
        8.951059700012209,
        9.689164573669434,
        8.94840906524658,
        6.137175025939943,
        6.094927131652831,
        10.664098411560058,
        12.561267341613771,
        8.536976295471192,
        10.429400184631348,
        8.217977119445798,
        7.568937675476075,
        6.632344184875489,
        7.631772163391112,
        8.02620691680908,
        9.129188011169433,
        8.806327308654787,
        10.189312828063965,
        12.276798988342286,
        11.519280036926268,
        8.440657035827638,
        8.278425498962402,
        9.429014808654784,
        8.50036351776123,
        7.1784482498168956,
        7.854945320129396,
        9.854976387023925,
        12.422762092590332,
        9.734300773620603,
        4.584884910583497,
        7.688347541809083,
        13.235735511779787,
        14.313721092224121,
        9.97486435699463,
        8.580501258850099,
        7.2922566299438465,
        8.890482353210452,
        7.71724897003174,
        7.509062980651855,
        6.592417274475096,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        10.965059623718261,
        9.559794746398925,
        6.94780741119385,
        9.577715263366699,
        11.333059425354001,
        9.646855781555175,
        9.229163887023926,
        11.947505744934084,
        10.391786079406739,
        13.16014575958252,
        13.135037803649901
      ],
      [
        8.590267066955569,
        9.76568860626221,
        7.135504692077639,
        10.535442588806152,
        8.887443687438967,
        9.149108695983887,
        7.610647315979005,
        8.703231010437012,
        9.1194164352417,
        8.526750160217283,
        8.064355201721192,
        7.389729927062987,
        7.637922950744631,
        9.33429047393799,
        8.79726877593994,
        9.34256702423096,
        10.865617881774902,
        11.135884269714353,
        8.226315101623534,
        9.680443855285645,
        8.872293968200683,
        9.950128135681155,
        9.013925392150881,
        8.242165916442872,
        5.308401054382323,
        9.117244453430175,
        13.421335395812989,
        11.47700235748291,
        5.222520042419433,
        7.483925239562989,
        9.569216346740724,
        10.643261268615724,
        10.402605262756346,
        7.730781639099121,
        3.682800071716308,
        8.896542930603026,
        13.460228675842288,
        10.096421180725098,
        8.024395927429197,
        7.138958549499513,
        8.696227912902835,
        7.0261902847290045,
        7.172822853088377,
        9.438034965515136,
        8.348139869689941,
        8.604225196838378,
        11.635451438903807,
        10.724192283630373,
        7.454880241394044,
        6.201854179382323,
        9.227312660217287,
        10.847602394104005,
        10.38098413848877,
        8.808546241760254,
        9.19771118927002,
        8.922340583801269,
        9.196592948913576,
        9.622114433288573,
        7.286208457946778,
        5.011465705871583,
        9.118062324523926,
        12.419095069885252,
        11.448392845153808,
        9.209395484924315,
        6.949719367980958,
        8.761950950622559,
        7.873995429992675,
        9.473710395812986,
        7.931511695861815,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.087654762268066,
        8.667905006408692,
        9.757471488952639,
        8.885437583923341,
        9.537528938293455,
        9.426462364196777,
        8.643760047912597,
        8.604815254211424,
        7.980617561340331,
        8.770096168518066,
        8.705853996276858
      ],
      [
        7.403662025451659,
        9.760974052429198,
        10.287678596496583,
        12.52545514678955,
        10.927396446228027,
        11.545045707702634,
        10.978240760803224,
        8.40696886444092,
        6.516405387878418,
        5.501921257019042,
        4.504695854187013,
        7.260908180236818,
        7.923780555725099,
        7.550798072814942,
        5.176447074890135,
        3.4707951431274413,
        6.714970939636231,
        8.472377601623535,
        8.94102089691162,
        8.308978645324707,
        9.547363502502442,
        7.323196098327635,
        4.6487028274536115,
        5.95880103302002,
        3.449777244567871,
        8.711871437072752,
        11.68269297027588,
        11.830165199279783,
        8.273088615417478,
        10.659146263122558,
        11.628800773620604,
        12.779686500549317,
        14.029032630920412,
        9.67566806793213,
        5.624073608398437,
        11.24747945404053,
        14.781955787658692,
        9.556344917297363,
        6.990249900817872,
        6.61573946380615,
        8.345140037536623,
        8.737614601135256,
        7.6321444473266595,
        6.8653962936401385,
        6.84691428375244,
        9.627757667541502,
        12.196462760925293,
        11.425103736877439,
        5.496692329406738,
        5.376539726257324,
        7.271367835998536,
        8.42682981109619,
        10.316697425842285,
        8.78618268585205,
        8.600218284606932,
        7.703044975280761,
        10.451349250793456,
        9.521069770812986,
        9.675280815124509,
        5.983470817565916,
        9.358908226013185,
        12.481125114440918,
        11.531147880554201,
        7.377345939636228,
        9.97602774810791,
        11.966749916076658,
        12.486886970520018,
        10.188729804992676,
        9.382406715393067,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.72944947052002,
        7.264227546691895,
        7.875496620178222,
        7.909118110656738,
        7.776752128601075,
        6.488441764831542,
        4.720060478210449,
        6.789697212219238,
        6.009431724548339,
        7.817242820739747,
        6.143959678649903
      ],
      [
        8.010660469055175,
        11.571844856262206,
        9.147636009216306,
        8.940416221618655,
        5.431554862976075,
        10.345879402160644,
        11.543373466491701,
        10.361385429382324,
        7.2040251693725565,
        5.980252159118653,
        7.95586449432373,
        7.99589459991455,
        8.234888740539553,
        9.72960932159424,
        9.029937370300292,
        6.961302772521972,
        8.288269355773927,
        8.188542137145994,
        9.770633003234863,
        9.046745079040527,
        8.679383644104004,
        8.76042458343506,
        5.347309700012207,
        8.370549476623538,
        10.305484519958497,
        10.782092979431152,
        12.866710884094239,
        10.205007972717283,
        7.360459190368651,
        9.325541816711425,
        10.119725883483888,
        11.14446546173096,
        12.720947761535644,
        6.863817085266113,
        7.00521817779541,
        9.52931241607666,
        11.814381324768064,
        9.87200742340088,
        8.481093406677246,
        6.970969947814943,
        6.540104667663574,
        9.246961082458498,
        10.187762001037598,
        5.158828819274902,
        4.253591682434081,
        6.654829429626464,
        11.920579368591309,
        9.867419654846191,
        7.464202873229979,
        7.873619880676269,
        6.609234031677246,
        7.605975715637207,
        8.605603187561037,
        8.305822166442873,
        6.338347526550294,
        7.9739789962768555,
        10.409725227355956,
        11.204159675598145,
        7.964319023132326,
        5.683943534851075,
        8.708987617492674,
        12.41285228729248,
        10.058826316833496,
        9.349341773986815,
        12.378976417541502,
        12.440288810729982,
        11.017086647033693,
        10.510113685607912,
        6.3590483932495125,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        7.6212490463256835,
        7.5396289596557615,
        9.164958122253417,
        7.507193031311036,
        6.20153636932373,
        7.846581703186036,
        5.291806999206543,
        8.883877357482909,
        11.045700401306153,
        8.53546083831787,
        7.708672172546386
      ],
      [
        10.240528434753418,
        13.225640144348144,
        9.328851570129395,
        7.073408729553222,
        6.255816932678222,
        5.975258537292483,
        10.976165718078612,
        12.31764736175537,
        11.616213249206545,
        9.189193870544436,
        10.812816703796386,
        10.331522651672365,
        9.944870658874514,
        11.897161415100097,
        10.456077003479002,
        10.380659141540526,
        5.673199562072753,
        7.169463218688964,
        7.72492406463623,
        6.738940910339355,
        7.202446067810058,
        6.004963645935057,
        11.52936811065674,
        12.70116611480713,
        6.549470252990723,
        7.535930183410645,
        12.41118885040283,
        8.525071434020994,
        8.100471504211427,
        9.870204093933104,
        9.07965433502197,
        8.942181022644043,
        9.719463752746584,
        5.861070335388185,
        7.102999061584473,
        8.832629570007324,
        11.637448036193845,
        12.687048042297363,
        8.706453895568849,
        6.509032722473144,
        12.11645795440674,
        12.798601844787598,
        10.07951932525635,
        3.9729724197387704,
        3.9000674285888657,
        9.604718482971194,
        13.38299161529541,
        9.592807746887207,
        8.992983345031739,
        8.827141899108888,
        10.447158882141114,
        8.021090812683106,
        10.717759727478025,
        10.534062873840334,
        8.343271217346192,
        9.49096605682373,
        12.38752156829834,
        10.945529808044434,
        7.069827186584472,
        3.983221023559569,
        6.522905174255371,
        7.993492958068849,
        8.677143547058105,
        9.48438776397705,
        7.351101905822752,
        6.988441505432128,
        5.483731452941896,
        9.660243537902833,
        7.762711708068849,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        9.141807746887206,
        8.516067863464357,
        8.74703536224365,
        9.941083595275877,
        11.380146919250489,
        8.996954338073731,
        7.591650978088378,
        6.76716464996338,
        7.419621543884276,
        8.235753776550293,
        8.456536552429199
      ],
      [
        13.266115287780764,
        11.109364402770996,
        8.568380729675294,
        5.913879051208497,
        5.410043891906739,
        7.440302085876464,
        8.698101585388184,
        8.896843299865722,
        10.497813804626464,
        7.331985160827635,
        7.781645256042481,
        11.821770271301268,
        13.200524223327637,
        14.090399528503418,
        11.970850975036619,
        8.98818402862549,
        6.974629356384277,
        5.865598381042481,
        5.80529285430908,
        5.851967048645019,
        4.1161946640014655,
        8.134089729309082,
        13.236878898620606,
        11.748919288635253,
        10.561507682800293,
        8.45436405181885,
        9.20800305938721,
        10.622746253967286,
        11.996538032531738,
        11.629262168884278,
        10.373360450744627,
        7.331094398498536,
        7.517338935852052,
        7.047553794860839,
        7.8987114639282225,
        7.055530113220215,
        9.24814563751221,
        11.380283058166505,
        9.235295021057127,
        10.270397911071775,
        9.919912178039553,
        11.3943413772583,
        12.616584877014162,
        6.298158088684083,
        5.044335670471192,
        7.8476390914917005,
        10.636612800598144,
        9.053972526550293,
        11.62444574737549,
        11.610716819763184,
        12.113680717468263,
        9.237332176208497,
        9.089292381286619,
        8.953478202819824,
        8.324163078308104,
        10.841624885559082,
        13.117194023132324,
        8.792610374450682,
        6.389577583312988,
        3.257344696044921,
        5.408624916076661,
        10.500324607849123,
        9.358336143493652,
        7.400966804504392,
        5.32698917388916,
        4.7054704208374005,
        5.385235420227051,
        10.665170722961427,
        11.803221412658694,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.669515830993653,
        8.293277015686037,
        9.34725316619873,
        11.47502152252197,
        9.674841789245605,
        7.326606819152833,
        7.815826026916504,
        6.353844337463379,
        5.931479072570802,
        6.9852782974243155,
        6.443893821716308
      ],
      [
        9.025813636779787,
        10.062053535461423,
        9.305923042297366,
        9.793393745422364,
        6.645162208557128,
        8.591582832336428,
        6.6776399307251,
        7.901635536193847,
        9.418333061218263,
        8.80831140899658,
        9.337062721252444,
        11.06417261505127,
        11.145643104553223,
        9.691125190734866,
        9.955151405334473,
        8.541680259704592,
        5.645311485290527,
        6.349549842834474,
        9.72260883331299,
        9.763088676452636,
        8.897788200378418,
        12.527498512268068,
        10.777089256286622,
        7.091761085510253,
        8.466910682678222,
        8.752058372497558,
        5.5137705001831065,
        7.922173805236817,
        10.173741569519045,
        12.50036237335205,
        9.110050010681153,
        5.25813404083252,
        8.837752403259277,
        10.227663978576658,
        10.04546212005615,
        9.62159854888916,
        8.485016090393067,
        6.238519264221193,
        10.637620857238769,
        8.334692573547365,
        8.809777412414551,
        10.903262702941895,
        10.702281761169434,
        8.23732347869873,
        10.37598964691162,
        8.807068672180176,
        9.611964103698732,
        8.463473487854003,
        9.541722969055176,
        8.054698143005371,
        8.792974281311036,
        8.488924690246584,
        8.449179573059084,
        7.838961082458496,
        5.3442192535400395,
        8.949306907653806,
        12.531440437316895,
        10.590458580017092,
        7.343591224670412,
        6.415496986389158,
        9.048207130432129,
        11.650312171936037,
        12.310890266418458,
        6.4985856857299815,
        8.105901008605954,
        8.861848030090332,
        6.857715248107908,
        6.75933457183838,
        10.048416648864745,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.889975547790527,
        8.430585594177245,
        7.686715248107909,
        5.354564201354982,
        11.29602396392822,
        12.636353309631346,
        9.179011039733886,
        7.03117244720459,
        8.940659812927244,
        10.51994863128662,
        8.831908515930174,
        8.875536155700683
      ]
    ]
}

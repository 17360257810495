import * as THREE from "three"
import CANNON from "cannon"
import terrainHeightMap from './terrainHeightMap.js'
export function defaultHeightField(){
    const {heightMap, pointDistance} = terrainHeightMap
    const terrainShape = new CANNON.Heightfield(heightMap, {elementSize: pointDistance});
    const heightfield = new CANNON.Body({ mass: 0, shape: terrainShape });
    heightfield.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
    heightfield.position.set(-850, 0, 850);
    return heightfield;
}


export function meshToHeightField(mesh, pointDistance) {
    console.log("inside meshToHeightField")
   
    const rayCaster = new THREE.Raycaster();
    const rayCasterPosition = new THREE.Vector3();
    const upAxis = new THREE.Vector3(0, 1, 0);


    const heightMap = [];

    const geometry = findGeometry(mesh);
    console.log("found geometry:", geometry)
    geometry.computeBoundingBox();
    let {
        min: {x: minX, y: minY, z: minZ},
        max: {x: maxX, z: maxZ},
    } = geometry.boundingBox;

    console.log("minX", minX)
    console.log("maxX", maxX)
    minX = -850
    maxX = 850
    minZ = -850
    maxZ = 850



    for (let x = minX; x <= maxX; x += pointDistance) {
        const heightDataRow = [];
        heightMap.push(heightDataRow);

        for (let z = maxZ; z >= minZ; z -= pointDistance) {

            rayCasterPosition.set(x, minY, z);
        
            rayCaster.set(rayCasterPosition, upAxis);

            const y = calculateMeshSurfaceDistanceByRayCast();

            heightDataRow.push(y);
        }
    }

    console.log("heightMap", heightMap)

    const terrainShape = new CANNON.Heightfield(heightMap, {elementSize: pointDistance});
    const heightfield = new CANNON.Body({ mass: 0, shape: terrainShape });
    heightfield.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
    heightfield.position.set(minX, 0, maxZ);

    return heightfield;

    
    function calculateMeshSurfaceDistanceByRayCast() {
       
        const [result] = rayCaster.intersectObject(mesh, true);  

        return result.distance
        
  
        
    }
}

function findGeometry(mesh) {
    let geometry;
    mesh.traverse((child) => {
        if (!geometry && child.type === 'Mesh' && child.geometry) {
        
            geometry = child.geometry;
        }
    });
    return geometry;
}
import * as THREE from "three"
import CANNON from "cannon"
import {EngineSound} from "./engineSound.js"

export class CannonVehicle {
    chassisShape = new CANNON.Box(new CANNON.Vec3(9.85, 8.05, 19.9))
    chassisBody = new CANNON.Body({mass:500})
    wheelOptions = {
        radius: 4,
        directionLocal: new CANNON.Vec3(0, -1, 0),
        suspensionStiffness: 50,
        suspensionRestLength: 0.3,
        frictionSlip: 5,
        dampingRelaxation: 2.3,
        dampingCompression: 4.4,
        maxSuspensionForce: 100000,
        rollInfluence:  0.5,
        axleLocal: new CANNON.Vec3(-1, 0, 0),
        chassisConnectionPointLocal: new CANNON.Vec3(),
        maxSuspensionTravel: 0.3,
        customSlidingRotationalSpeed: -30,
        useCustomSlidingRotationalSpeed: true,
    };
    vehicle = new CANNON.RaycastVehicle({
        chassisBody: this.chassisBody,
        indexRightAxis: 0,
        indexUpAxis: 1,
        indexForwardAxis: 2,
    });
    wheelPositionYOffset = 6
    frontWheelPositionZOffset = 13
    backWheelPositionZOffset = 11
    wheelPositionXOffset = 9.4
    wheelBodies = [];
    wheelOrientation = new CANNON.Quaternion();
    initialPosition = new THREE.Vector3(0,50, 0)
    initialRotation = new THREE.Quaternion().setFromAxisAngle(new CANNON.Vec3(0, -1, 0), -Math.PI/2)
    maxSteeringValue = .5;
    maxForceOnFrontWheels = 4000;
    maxForceOnRearWheels = 3950;
    brakeForce = 1;
    engineSound = new EngineSound()

    constructor(){
        
        this.engineSound.play()
        this.chassisBody.addShape(this.chassisShape)
        this.wheelOptions.chassisConnectionPointLocal.set(this.wheelPositionXOffset, -this.wheelPositionYOffset, -this.frontWheelPositionZOffset);
        this.vehicle.addWheel(this.wheelOptions);
        this.wheelOptions.chassisConnectionPointLocal.set(-this.wheelPositionXOffset, -this.wheelPositionYOffset, -this.frontWheelPositionZOffset);
        this.vehicle.addWheel(this.wheelOptions);
        this.wheelOptions.chassisConnectionPointLocal.set(this.wheelPositionXOffset, -this.wheelPositionYOffset, this.backWheelPositionZOffset);
        this.vehicle.addWheel(this.wheelOptions);
        this.wheelOptions.chassisConnectionPointLocal.set(-this.wheelPositionXOffset, -this.wheelPositionYOffset, this.backWheelPositionZOffset);
        this.vehicle.addWheel(this.wheelOptions);
        this.wheelOrientation.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), Math.PI / 2);
        this.addToWorld = this.vehicle.addToWorld.bind(this.vehicle);
        
        this.vehicle.wheelInfos.forEach((wheel) => {
            const wheelShape = new CANNON.Cylinder(wheel.radius, wheel.radius, wheel.radius / 2, 8);
            const wheelBody = new CANNON.Body({
                type: CANNON.Body.KINEMATIC,
                collisionFilterGroup: 0,
            });
        
            wheelBody.addShape(wheelShape, CANNON.Vec3.ZERO, this.wheelOrientation);
            this.wheelBodies.push(wheelBody);
        });
        
    }
    updateVisuals(chassisMesh, wheelMeshes, wheelBodies, engineSound) {
        const velocity = Math.abs(this.chassisBody.velocity.x)  + Math.abs(this.chassisBody.velocity.z)
        engineSound.adjustRate(velocity/75)
        
        let transform
        let wheelBody
        var localPoint = new CANNON.Vec3(0,8,0);
        var impulse = new CANNON.Vec3(0,-2000,0);
        this.chassisBody.applyLocalImpulse(impulse, localPoint)
        for (let i = 0; i < this.wheelInfos.length; i++) {
            this.updateWheelTransform(i);
            transform = this.wheelInfos[i].worldTransform;
            wheelBody = wheelBodies[i];
            
            
            wheelBody.position.copy(transform.position);
            wheelBody.quaternion.copy(transform.quaternion);

            wheelMeshes[i].position.copy(wheelBody.position);
            wheelMeshes[i].quaternion.copy(wheelBody.quaternion);
        }
        chassisMesh.position.copy(this.chassisBody.position);
        chassisMesh.quaternion.copy(this.chassisBody.quaternion);
        chassisMesh.translateOnAxis(new THREE.Vector3(0, 0, 1), 0.6);
    }

    addMeshesToWorld(world, meshes) { 
        const wheelMeshes = [
            meshes['Jeep-WheelFL'], meshes['Jeep-WheelFR'], meshes['Jeep-WheelBL'], meshes['Jeep-WheelBR'],
        ];
        this.wheelBodies.forEach(wheelBody => world.addBody(wheelBody));
        world.addEventListener('postStep', this.updateVisuals.bind(this.vehicle,meshes["Jeep-Chassis"], wheelMeshes, this.wheelBodies, this.engineSound)); 
        this.vehicle.addToWorld(world)
        this.reset()   
  
        
    }

    reset(){

        this.vehicle.chassisBody.position.copy(this.initialPosition)
        this.vehicle.chassisBody.quaternion.copy(this.initialRotation)
        this.vehicle.chassisBody.velocity.set(0,0,0)
        this.vehicle.chassisBody.angularVelocity.set(0,0,0)
    }        
    updateMovement(direction, steeringDirection, brakeForceMultiple){
       
        [0, 1].forEach(wheelIndex => this.vehicle.applyEngineForce(this.maxForceOnFrontWheels * direction, wheelIndex));
        [2, 3].forEach(wheelIndex => this.vehicle.applyEngineForce(this.maxForceOnRearWheels * direction, wheelIndex));
        
        
        [0, 1].forEach(wheelIndex => this.vehicle.setSteeringValue(this.maxSteeringValue * steeringDirection, wheelIndex));

        [2, 3].forEach(wheelIndex => this.vehicle.setBrake(this.brakeForce * brakeForceMultiple, wheelIndex));
        
       

    }
    
    
}







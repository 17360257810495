import nipplejs from 'nipplejs'


function upDownMultiplier(angle){
   if (angle > 0 && angle < 180){
       return 1
   }
   else{
       return -1
   }
}
function leftRightMultiplier(angle){
 
        return (1 - Math.abs(180 - angle)/90)

    
}
export default function initMobileControls(cannonVehicle, interactibles) {
    document.getElementsByClassName("mobileButtons")[0].style.display = "flex"
    document.getElementById("interactButton").addEventListener("touchstart", function(evt) {
        for(let interactible of interactibles ){
            if(interactible.isInScene()){
                interactible.interact()
            }
        }
    })
    document.getElementById("resetButton").addEventListener("touchstart", function(evt) {
        cannonVehicle.reset()
    })
    document.getElementById("brakeButton").addEventListener("touchstart", function(evt) {
        cannonVehicle.updateMovement(0, 0, 1)
    })
    let options = {
        zone: document.getElementById('zone_joystick'),
        mode: 'static',
        position: {
            left: '75px',
            bottom: '75px'
        }
    }


    var manager = nipplejs.create(options)

    console.log(manager)
    manager.on("move", function (evt, data) {
        let enginePower =  data.distance/50
        let upDown = upDownMultiplier(data.angle.degree)
        
        let leftRight = leftRightMultiplier(data.angle.degree)
        cannonVehicle.updateMovement(upDown * enginePower , leftRight, 0)
   
    })
    
    manager.on("end", function(){
        cannonVehicle.updateMovement(0,0, 0)
    })

}

import {Howl, Howler} from "howler"


export class EngineSound{
    sound= null
    constructor(){
        let howl = new Howl({src:"./mitsubishiengine.wav", format: ['wav'], loop: true, volume: .5, rate: .8})
        this.sound = {name: "EngineSound", howl: howl}
        
    }
    play(){
        this.sound.howl.play()
        
    }
    updateVolume(rate){
        this.sound.howl.volume(rate * .5 )
    }

    stop(){
        this.sound.howl.pause()
    }
    adjustRate(rate){
        const newRate = (rate * 40 + 60) / 100
     
        // console.log("newRate", newRate)
        this.updateVolume(newRate)
        this.sound.howl.rate(newRate)
        
    }


}
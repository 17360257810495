import * as THREE from "three"
export class ProjectInfoInteractable{
    inScene = false
    position = {x:-130,y: 25,z: -220}
    rotationOrder = "YXZ"
    xRotation = -1.1
    yRotation = 1.9
    container= null
    constructor(position, rotation, content, link){
        this.container = new ThreeMeshUI.Block({
            contentDirection: "column",
            backgroundOpacity: 0,
            margin: 1,
         })

         this.link = link
    
        
        //  this.container.position.set(-130,20,-230)
         this.container.position.set(position.x,position.y,position.z)
         this.container.rotation.order = 'YXZ'
         this.container.rotation.x = rotation.x
         this.container.rotation.y = rotation.y
        
         this.container.set({
            fontFamily: './custom-msdf.json',
            fontTexture: './custom.png'
        })
      
        const textBlock = new ThreeMeshUI.Block({
            height: 8,
            width: 16,
            margin: 0.05,
            offset: 0.1,
            backgroundOpacity: 0.8,
            borderRadius: 1
        })
        const text = new ThreeMeshUI.Text({
            content: content
        })
        textBlock.add(text)
        text.set({
            fontColor: new THREE.Color(0xffffff),
            fontSize: 1.5
        })
        textBlock.set({
        
  
        
        })
      


        const promptBlock = new ThreeMeshUI.Block({
            height: 3,
            width: 16,
            // margin: 0.05,
            offset: 0.1,
            backgroundOpacity: 0.8,
            borderRadius: 1
        })
     
        const promptText = new ThreeMeshUI.Text({
            content: "Press I to visit"
        })
        promptBlock.add(promptText)
        promptText.set({
            fontColor: new THREE.Color(0xffffff),
            fontSize: 1.5
        })
        promptBlock.set({
            alignContent:'center',
            justifyContent: 'end',
            padding: 0.5
        })
        // topContainer.add(imageBlockParent,textBlock)
   
        this.container.add(textBlock, promptBlock)

    }
    inInteractiveRegion(userPosition){
        return (this.container.position.distanceTo(userPosition) < 100)
    }
    isInScene(){
        return this.inScene
    }
    addToScene(gScene){
        gScene.add(this.container)
        this.inScene = true
    }
    removeFromScene(gScene){
        gScene.remove(this.container)
        this.inScene = false
    }

    interact(){
   
        window.open(this.link)
    }
}

export default function initDesktopControls(cannonVehicle, interactibles) {
    const keysPressed = new Set();
    const isKeyDown = (key) => keysPressed.has(key);
    document.getElementsByClassName("close")[0].addEventListener("click", () => {
        document.getElementsByClassName("keybindingsWindowContainer")[0].style.display = "none"
    })

    document.getElementsByClassName("keybindingsHint")[0].style.display = "block"



    onkeydown = onkeyup = (e) => {
        let pressedKey = e.key.toUpperCase();
        
        if (e.type === 'keydown' && e.repeat) {
            console.log("inside keydown block")
            return;
        }
        if (e.type === 'keyup') {
            keysPressed.delete(pressedKey);
        } else {
            keysPressed.add(pressedKey);
        }
        if(pressedKey === 'W'  || pressedKey ==='A' || pressedKey ==='S'|| pressedKey ==='D'|| pressedKey ===' ' || pressedKey === "ARROWUP" || pressedKey === "ARROWDOWN" || pressedKey === "ARROWLEFT"|| pressedKey === "ARROWRIGHT"){
  
            const direction = isKeyDown('W') || isKeyDown("ARROWUP") ? 1 : isKeyDown('S') || isKeyDown("ARROWDOWN") ? -1 : 0;

            // lastWPress = 

            const steeringDirection = isKeyDown('A') || isKeyDown("ARROWLEFT") ? 1 : isKeyDown('D') || isKeyDown("ARROWRIGHT") ? -1 : 0;
        
            const brakeMultiplier = Number(isKeyDown(' '));
    
            cannonVehicle.updateMovement(direction, steeringDirection, brakeMultiplier)
        }
        else if(pressedKey === 'R' && isKeyDown('R')){
            cannonVehicle.reset()
        }
        else if(pressedKey === 'I' && isKeyDown('I')){
            for(let interactible of interactibles ){
                if(interactible.isInScene()){
                    interactible.interact()
                }
            }
        }
        else if(pressedKey === "K" && isKeyDown("K")){
    
            document.getElementsByClassName("keybindingsWindowContainer")[0].style.display = "flex"
        
        }
        else if(pressedKey === "ESCAPE" && isKeyDown("ESCAPE")){
    
            document.getElementsByClassName("keybindingsWindowContainer")[0].style.display = "none"
        
        }
        
    };
}



import * as THREE from "three"

const cameraMovementSpeed = 0.02; 
const cameraLookPositionHeightOffset = 15;
const cameraMountPosition = new THREE.Vector3();
const cameraLookPosition = new THREE.Vector3();
const chaseCameraMountPositionHelper = new THREE.Object3D();

export function initChaseCamera(camera, target) {
    camera.fov = 50;
    chaseCameraMountPositionHelper.position.set(0, 25, 75);
    target.add(chaseCameraMountPositionHelper);
}

 
export function updateChaseCamera(camera, target) {   
    chaseCameraMountPositionHelper.getWorldPosition(cameraMountPosition);

    if (cameraMountPosition.y < target.position.y) {
        cameraMountPosition.setY(target.position.y);
    }

    camera.position.lerp(cameraMountPosition, cameraMovementSpeed);
    cameraLookPosition.copy(target.position).y += cameraLookPositionHeightOffset;

    camera.lookAt(cameraLookPosition);

}
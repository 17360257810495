// import { Howl, Howler } from "howler";

import { Howler } from "howler"

export class SpatialSound{
    sounds = []
    count = 0
    constructor(){
        let howl = new Howl({src:"./80sStation.mp3" , format: ['mp3']})
        this.sounds.push({name:"80s", howl: howl})

        

    }
    play(name){
     
        for(let sound of this.sounds){
            if(sound.name === name){
   
                 let soundId = sound.howl.play()
                 sound.howl.mute(false)
                //  sound.howl.seek(Math.random() * 1700)
                 sound.howl.pos(-50,145,49, soundId)

                sound.howl.pannerAttr({
                    panningModel: 'HRTF',
                    refDistance: 30,
                    rolloffFactor: 2,
                    distanceModel: 'inverse'
                  }, soundId);
            }
        }
    }
    stop(name){
    
        for(let sound of this.sounds){
            if(sound)
                sound.howl.pause()
                
            // }
        }
    }
    updateListenerPosition(position, rotation){
        let y = -rotation.z
        let x = rotation.x
        let posY = -position.x
        let posX = -position.z

        Howler.pos(posX,posY,49)
      
        Howler.orientation(x,y,0,0,0,1)
    }
}
import * as THREE from "three"
export class RadioInteractable{
    inScene = false
    position = new THREE.Vector3(20,25,20)
    rotationOrder = "YXZ"
    xRotation = -1.1
    yRotation = 1.9
    container= null
    genres=["Off", "80s"]
    genreIndex = 0
    text = null
    textBlock = null
    sounds = null
    constructor(sounds){
        this.sounds = sounds
        this.container = new ThreeMeshUI.Block({
            contentDirection: "column",
            backgroundOpacity: 0,
            margin: 1,
         })
        
         this.container.position.set(-135,25,47)
         this.container.rotation.order = 'YXZ'
         this.container.rotation.x = -1.1
         this.container.rotation.y = 2.35
        
         this.container.set({
            fontFamily: './custom-msdf.json',
            fontTexture: './custom.png'
        })
      
        this.textBlock = new ThreeMeshUI.Block({
            height: 6,
            width: 15,
            margin: 0.05,
            offset: 0.1,
            backgroundOpacity: 0.8,
            borderRadius: .5
        })
        this.text = new ThreeMeshUI.Text({
            content: "< " + this.genres[this.genreIndex] + " >"
        })
        this.textBlock.add(this.text)
        this.text.set({
            fontColor: new THREE.Color(0xffffff),
            fontSize: 2.2
        })
        this.textBlock.set({
            alignContent:'center',
            justifyContent: 'center',
            padding: 0.1
        })
        const promptBlock = new ThreeMeshUI.Block({
            height: 3,
            width: 15,
            offset: 0.1,
            backgroundOpacity: 0.8,
            borderRadius: 1
        })
     
        const promptText = new ThreeMeshUI.Text({
            content: "Press I to Play"
        })
        promptBlock.add(promptText)
        promptText.set({
            fontColor: new THREE.Color(0xffffff),
            fontSize: 1.5
        })
        promptBlock.set({
            alignContent:'center',
            justifyContent: 'end',
            padding: 0.5
        })
        this.container.add(this.textBlock, promptBlock)

    }
    inInteractiveRegion(userPosition){
        return (this.container.position.distanceTo(userPosition) < 100)
    }
    isInScene(){
        return this.inScene
    }
    addToScene(gScene){
        gScene.add(this.container)
        this.inScene = true
    }
    removeFromScene(gScene){
        gScene.remove(this.container)
        this.inScene = false
    }
    interact(){
        this.sounds.stop(this.genres[this.genreIndex])
        this.genreIndex = (this.genreIndex+1)%2
        console.log("< " + this.genres[this.genreIndex] + " >")
        this.text.set({
            content:  "< " + this.genres[this.genreIndex] + " >"
        })
       
        this.sounds.play(this.genres[this.genreIndex])
        
  
    }
}